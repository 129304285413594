import { differenceInDays } from 'date-fns';
import * as yup from 'yup';
import { ExternalFacData } from '../data/categories/ExternalFacData';
import { formatToUsableDate } from '../functions';

const externalFacID = ExternalFacData.id;
const MAX_FILE_SIZE = 5000000;
const MAX_STRING_LENGTH = 255;

const isDate = (date) => {
    return !!date && date instanceof Date && !isNaN(date.valueOf());
}

export const courseSchema = yup.object({
    where: yup.string()
        .test('isRequired', 'El nombre de la organización / institución es obligatorio', function (where) {
            if (this.parent.faculty !== externalFacID) return true;
            return where.length > 0;
        })
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    title: yup.string()
        .required('El título es obligatorio')
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    objective: yup.string()
        .required('El objetivo es obligatorio')
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    faculty: yup.string()
        .notOneOf(['none'], 'Seleccione una opción válida')
        .required('La facultad es obligatoria'),
    ri: yup.string()
        .required('El Registro Institucional es obligatorio')
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    initDate: yup.date()
        .required('La fecha de inicio es obligatoria'),
    endDate: yup.date()
        .test('initDateIsBefore', "La fecha de terminación no puede ser antes que la de inicio", function (endDate) {
            if (isDate(this.parent.initDate)) {
                const initDateMoved = formatToUsableDate(this.parent.initDate.toISOString());
                const endDateMoved = formatToUsableDate(endDate.toISOString());
                return differenceInDays(endDateMoved, initDateMoved) >= 0;
            }
            return false;
        })
        .test('initDateExists', 'Por favor ingrese la fecha de inicio', function (_) {
            return isDate(this.parent.initDate);
        })
        .required('La fecha de terminación es obligatoria'),
    contact: yup.string()
        .required('El nombre del contacto es obligatorio')
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    tel: yup.string()
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'El número de teléfono no es válido')
        .required('El número de teléfono es obligatorio'),
    ext: yup.string()
        .matches(/^(\d{4}|\d{0})$/i, 'El número de extensión no es válido'),
    email: yup.string()
        .email('El correo electrónico no es válido')
        .required('El correo electrónico es obligatorio')
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    image: yup.mixed()
        .test("fileSize", "El archivo es muy grande (mayor que 5 MB)", (value) => {
            if (value) return value.size <= MAX_FILE_SIZE;
            return true;
        }),
    cost: yup.string()
        .test('isNumber', 'El número no es válido', n => !isNaN(n) || n === null || n === undefined)
        .test('isGreaterThanOrEqual', 'El costo no puede ser negativo', n => n >= 0)
        .max(9, 'El campo puede tener como máximo 9 caracteres'),
    publish: yup.array()
        .test('hasCorrectValue', 'El valor de casilla no es válido', (value) => {
            if (value.length > 0) return value[0] === 'yes';
            return true;
        })
        .required('La opción de "publicado" es obligatoria'),
});