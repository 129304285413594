
export const ActionFormButton = ({ value, onClick, col = "6", type = 'button', classType = 'secondary', disabled = false }) => {
    
    return (
        <div className={`col-${col}`}>
            <div className="d-grid gap-2">
                <button
                    className={`btn btn-${classType} text-button`}
                    type={type}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {value}
                </button>
            </div>
        </div>
    )
}
