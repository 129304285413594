
export const FormSelectInput = ({ label,
    name,
    value,
    disabled = false,
    options = [{
        value: 'value',
        option: 'option'
    }],
    required = false,
    onChange,
    validFeedback,
    invalidFeedback,
}) => {

    const display = invalidFeedback?.length > 0 ? 'd-block' : 'd-none';

    return (
        <div className="py-2">
            {label && <label htmlFor={name} className="form-label">
                {label}{required && <span className="text-danger">&nbsp;*</span>}
            </label>}
            <select
                disabled={disabled}
                name={name}
                className="form-select"
                required={required}
                value={value}
                onChange={onChange}
            // aria-label="Selecciona una opcion"
            >
                {options.map(option =>
                    <option
                        key={name + '-' + option.value}
                        value={option.value}
                    >
                        {option.option}
                    </option>
                )}
            </select>
            <div className="valid-feedback">
                {validFeedback}
            </div>
            <div className={`invalid-feedback ${display}`}>
                {invalidFeedback}
            </div>
        </div>
    )
}
