import useAxios from "../hooks/useAxios";
import useAlert from "../hooks/useAlert";
import { useForm } from "../hooks/useForm";
import _ from 'lodash';

import { Link } from "react-router-dom";

import { FormTextInput } from "../components/form/FormTextInput"
import { PageWithCenteredSquare } from "../components/general/PageWithCenteredSquare";
import { loginSchema } from "../schemas/loginSchema";
import { useLoading } from "../hooks/useLoading";
import { LargeButton } from "../components/general/LargeButton";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

const sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

export const LoginPage = (props) => {

  const {
    email,
    password,
    onInputChange,
    errors,
    validateFormState,
    hasBeenSubmited
  } = useForm(
    {
      email: "",
      password: "",
    },
    loginSchema,
  );
  const { post } = useAxios();
  const { openAlert } = useAlert();
  const [captcha, setCaptcha] = useState(null);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const loading = useLoading();

  const handleSubmit = async (event) => {
    event.preventDefault();

    hasBeenSubmited();
    const isValid = validateFormState();
    if (!isValid) {
      openAlert(
        "Errores en los campos",
        <>
          Por favor revise los campos del formulario
          e inténtelo nuevamente.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
      return;
    }

    if (!captcha) {
      openAlert(
        "Revise el CAPTCHA",
        <>
          Por favor verifique el CAPTCHA e inténtelo
          nuevamente.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
      return;
    }

    if (!verifiedCaptcha) {
      try {
        loading.setLoading(true);
        const body = { captcha };
        const response = await post("/auth/captcha", body);
        setVerifiedCaptcha(true);
      } catch (error) {
        const message = _.get(error, "response.data.errors[1].msg");
        openAlert(
          "Error al autenticarse",
          <>
            Ocurrió un error al verificar el CAPTCHA.<br />
            {message
              ? <b>{message}</b>
              : "Inténtelo de nuevo más tarde"}.
          </>,
          undefined, true, "secondary", undefined, undefined,
        );
        // console.log({ error });
        return;
      } finally {
        loading.setLoading(false);
      }
    }

    try {
      loading.setLoading(true);
      const body = { correo: email, password };
      const response = await post("/auth/login", body);
      if (response.data && response.data.token && response.data.refresh) {
        const token = response.data.token;
        const refresh = response.data.refresh;

        localStorage.setItem('token', token);
        localStorage.setItem('refresh', refresh);

        window.location.reload(false);
      }
    } catch (error) {
      const message = _.get(error, "response.data.errors[0].msg");
      openAlert(
        "Error al autenticarse",
        <>
          Ocurrió un error al intentar autenticarse.<br />
          {message
            ? <b>{message}</b>
            : "Inténtelo de nuevo más tarde"}.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
      // console.log({ error });
      loading.setLoading(false);
    }
  };

  const onChangeCaptcha = (value) => {
    setCaptcha(value);
    setVerifiedCaptcha(false);
  }

  return (
    <PageWithCenteredSquare>
      <form onSubmit={handleSubmit} className="needs-validation" noValidate>

        <h5 className="mt-2 text-uni">Inicio de sesión</h5>

        <FormTextInput
          label="Correo electrónico"
          name="email"
          value={email}
          type="text"
          required={true}
          onChange={onInputChange}
          placeholder='correo@electronico.mx'
          invalidFeedback={errors.email}
        />

        <FormTextInput
          label="Contraseña"
          name="password"
          value={password}
          type="password"
          required={true}
          onChange={onInputChange}
          placeholder='************'
          invalidFeedback={errors.password}
        />

        <div className="mt-2 text-center">
          <ReCAPTCHA
            style={{ display: 'inline-block' }}
            sitekey={sitekey}
            onChange={onChangeCaptcha}
          />
        </div>

        <div className="pt-3">
          <LargeButton value="Iniciar sesión" classType="primary" type="submit" />
        </div>

        <div className="login-button-center">
          <Link to="/forgot-password" className="btn btn-link login-button-decoration">
            ¿Olvidó su contraseña?
          </Link>
        </div>

      </form>
    </PageWithCenteredSquare>
  );
};
