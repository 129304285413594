import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageTitle } from "../components/general/PageTitle";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { HelpData } from "../data/help/HelpData";
import { PageParagraph } from "../components/general/PageParagraph";
import { LargeButton } from "../components/general/LargeButton";
import { PageImage } from "../components/general/PageImage";
import { PageSubtitle } from "../components/general/PageSubtitle";
import { PageVideo } from "../components/general/PageVideo";


import { Accordion } from '../components/general/Accordion'
import { roleValues } from "../data/role";
import useMyInfo from "../hooks/useMyInfo";


export const HelpWithPage = () => {

  const navigate = useNavigate();
  const { helpID } = useParams();

  const user = useMyInfo();
  const role = user.rol || roleValues.USER_ROLE;

  const helpSection = HelpData
    .filter((item) => role !== roleValues.USER_ROLE || item.visibleToUser)
    .find(help => help.path === helpID);

  if (!helpSection) return <Navigate to="/help/" />

  const links = [
    { name: "Inicio", path: "/" },
    { name: "Ayuda", path: "/help" },
    { name: helpSection.title, path: "/help/" + helpSection.path },
  ];

  return (
    <PageWithSidebar links={links}>

      <PageTitle>{helpSection.title}</PageTitle>

      {
        helpSection.video &&
        <>
          {/*<PageSubtitle>Demostración</PageSubtitle>*/}
          <div className="mb-3">
            <PageParagraph>{helpSection.video?.text}</PageParagraph>
            <PageVideo src={helpSection.video?.src} />
          </div>
        </>
      }

      <div className="pb-5">

        {/*helpSection.steps*/}
        <Accordion questions={helpSection.steps} />
      </div>

      <Link to="/help">
        <LargeButton value="Regresar" classType="secondary" />
      </Link>

    </PageWithSidebar>
  );
};