import _ from 'lodash';

import useAxios from "../useAxios";
import { useLoading } from '../useLoading';
import useMyInfo from '../useMyInfo';

import { log } from '../../functions';

export const useUsersCRUD = (logs = false) => {

    const { get, post, put, del } = useAxios();
    const userInfo = useMyInfo();
    const { setLoading } = useLoading();

    const getUser = async (ID) => {
        try {
            setLoading(true);
            const response = await get(`/usuarios?id=${ID}`);
            const user = _.get(response, "data.items");
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
                data: {
                    user: {
                        name: user.nombre,
                        email: user.correo,
                        role: user.rol,
                        faculty: user.facultad,
                        img: user.img,
                    },
                },
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const getMyUser = async () => {
        try {
            setLoading(true);
            const response = await userInfo.getMyInfo();
            const user = { ...userInfo };
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
                data: {
                    user: {
                        name: user.nombre,
                        email: user.correo,
                        role: user.rol,
                        faculty: user.facultad_id,
                        img: user.img,
                    },
                },
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const addUser = async (data) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('nombre', data.name);
            formData.append('correo', data.email);
            formData.append('rol', data.role);
            formData.append('facultad', data.faculty);
            if (data.image) formData.append('archivo', data.image);

            const response = await post(`usuarios`, formData);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const editUser = async (data, ID) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('nombre', data.name);
            formData.append('correo', data.email);
            formData.append('rol', data.role);
            formData.append('facultad', data.faculty);
            formData.append('password', data.password);
            if (data.image) formData.append('archivo', data.image);

            const response = await put(`usuarios/${ID}`, formData);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const deleteUserImage = async (ID) => {
        try {
            setLoading(true);
            const response = await del(`uploads/files/usuarios/${ID}`);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const restoreUserPassword = async (data) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('new_password', data.password);
            formData.append('current_password', data.oldPassword);

            const response = await put("/auth/change_password", formData);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const deleteUser = async (ID) => {
        try {
            setLoading(true);
            const response = await put(`usuarios/status/${ID}`, { estado: false });
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const activateUser = async (ID) => {
        try {
            setLoading(true);
            const response = await put(`usuarios/status/${ID}`, { estado: true });
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    return {
        getUser,
        getMyUser,
        addUser,
        editUser,
        deleteUserImage,
        restoreUserPassword,
        deleteUser,
        activateUser,
    };
}