import { useContext } from "react";
import { AlertContext, on, clearListeners } from "../providers/AlertProvider";

const useAlert = () => {
  const {
    title,
    message,
    severity,
    setTitle,
    setMessage,
    setSeverity,
    setOneOption,
    setButtonColors,
    setShow,
    closeOn,
    clearCurrentTimeout,
  } = useContext(AlertContext);

  const openAlert = (
    title = "",
    message = "",
    severity = "",
    oneOption = false,
    classNameAcceptBtn = 'danger',
    classNameCancelBtn = 'secondary',
    closeOnTime,
  ) => {
    if (!message) return false;
    return new Promise((resolve, reject) => {
      setTitle(title);
      setMessage(message);
      setSeverity(severity);
      setOneOption(oneOption);
      setButtonColors({
        accept: classNameAcceptBtn,
        cancel: classNameCancelBtn,
      });
      setShow(true);
      if (closeOnTime && closeOnTime >= 0) {
        closeOn(closeOnTime);
      } else {
        clearCurrentTimeout();
      }
      clearListeners();
      on("accept", () => resolve(true));
      on("cancel", () => resolve(false));
    });
  };

  return { openAlert };
};

export default useAlert;
