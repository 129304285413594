import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

registerLocale('es', es);

export const FormDateInput = ({
    label,
    name,
    value,
    required = false,
    onChange,
    placeholder,
    minDate = null,
    maxDate = null,
    validFeedback,
    invalidFeedback,
}) => {

    const onInputChange = (date = new Date()) => {
        const value = date;
        onChange({ target: { name, value } });
    }

    const display = invalidFeedback?.length > 0 ? 'd-block' : 'd-none';

    return (
        <div className="py-2">
            {label && <label htmlFor={name} className="form-label">
                {label}{required && <span className="text-danger">&nbsp;*</span>}
            </label>}
            <DatePicker
                id={name}
                name={name}
                selected={value}
                dateFormat="dd/MM/yyyy"
                onChange={onInputChange}
                placeholderText={placeholder}
                required={required}
                className="form-control"
                popperClassName="shadow rounded"
                todayButton="Hoy"
                minDate={minDate}
                maxDate={maxDate}
                locale="es"
                closeOnScroll

            />
            <div className="valid-feedback">
                {validFeedback}
            </div>
            <div className={`invalid-feedback ${display}`}>
                {invalidFeedback}
            </div>
        </div>
    )
}
