import _ from 'lodash';

import useAxios from "../useAxios";
import { useLoading } from '../useLoading';

import { log } from '../../functions';

export const useCoursesCRUD = (logs = false) => {

    const { get, post, put, del } = useAxios();
    const { setLoading } = useLoading();

    const getCourse = async (ID) => {
        try {
            setLoading(true);
            const response = await get(`/cursos?id=${ID}`);
            const course = _.get(response, "data.items[0]");
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
                data: {
                    course: {
                        title: course.titulo,
                        faculty: course.facultad,
                        ri: course.registro_institucional,
                        initDate: course.fecha_inicio,
                        endDate: course.fecha_final,
                        contact: course.contacto,
                        tel: course.telefono,
                        ext: course.extension?.toString().padStart(4, '0') ?? "",
                        email: course.correo,
                        cost: course.precio ?? 0,
                        publish: course.publicado,
                        img: course.img,
                        where: _.get(course, "extradata.institucion", ""),
                        objective: _.get(course, "extradata.objetivo", ""),
                        extra: course.extradata,
                    },
                },
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const addCourse = async (data) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('titulo', data.title);
            formData.append('registro_institucional', data.ri);
            formData.append('facultad', data.faculty);
            formData.append('fecha_inicio', data.initDate);
            formData.append('fecha_final', data.endDate);
            formData.append('contacto', data.contact);
            formData.append('correo', data.email);
            formData.append('telefono', data.tel);
            formData.append('extension', data.ext);
            formData.append('precio', data.cost);
            formData.append('publicado', data.publish);
            formData.append('extradata', JSON.stringify({
                institucion: data.where,
                objetivo: data.objective,
            }));
            if (data.image) formData.append('archivo', data.image);

            const response = await post(`cursos`, formData);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const addCourseByFile = async (data) => {
        try {
            const formData = new FormData();
            formData.append('socketid', data.socket.id);
            formData.append('archivo', data.excelFile);
            formData.append('zip', data.zipFile);

            const response = await post(`cursos/excel`, formData);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        }
    }

    const editCourse = async (data, ID) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('titulo', data.title);
            formData.append('registro_institucional', data.ri);
            formData.append('facultad', data.faculty);
            formData.append('fecha_inicio', data.initDate);
            formData.append('fecha_final', data.endDate);
            formData.append('contacto', data.contact);
            formData.append('correo', data.email);
            formData.append('telefono', data.tel);
            formData.append('extension', data.ext);
            formData.append('precio', data.cost);
            formData.append('publicado', data.publish);
            formData.append('extradata', JSON.stringify({
                institucion: data.where,
                objetivo: data.objective,
            }));
            if (data.image) formData.append('archivo', data.image);

            const response = await put(`cursos/${ID}`, formData);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const deleteCourseImage = async (ID) => {
        try {
            setLoading(true);
            const response = await del(`uploads/files/cursos/${ID}`);
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const deleteCourse = async (id) => {
        try {
            setLoading(true);
            const response = await put(`cursos/status/${id}`, { estado: false });
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    const unarchiveCourse = async (id) => {
        try {
            setLoading(true);
            const response = await put(`cursos/status/${id}`, { estado: true });
            log(logs, response);
            return {
                ok: response.status === 200,
                response: response,
            };
        } catch (error) {
            log(logs, error);
            return {
                ok: false,
                response: error.response,
                error: _.get(error, "response.data.errors[0].msg"),
            };
        } finally {
            setLoading(false);
        }
    }

    return {
        getCourse,
        addCourse,
        addCourseByFile,
        editCourse,
        deleteCourseImage,
        deleteCourse,
        unarchiveCourse,
    };
}