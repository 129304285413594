import { PageImage } from "../../../components/general/PageImage";
import { ListContent } from '../../../components/general/ListContent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMortarBoard } from '@fortawesome/free-solid-svg-icons'



import addCourse1 from '../../../assets/img/addCourse/addCourse1.png'
import modifyCourse2 from '../../../assets/img/modifyCourse/modifyCourse2.png'
import modifyCourse3 from '../../../assets/img/modifyCourse/modifyCourse3.png'
import modifyCourse4 from '../../../assets/img/modifyCourse/modifyCourse4.png'


import { stepsAddCourseByFileData } from './stepsList/stepsModifyCourseInfo'





export const stepsModifyCourse = [
  
     
  {
    title: 
    <span className='text-uni'>
      <span className='fw-bold'>Paso 1: </span> 
      Diríjase a la página de cursos
    </span>,
    content: 
      <div className="px-3 py-3">
        <p>
          En la barra lateral izquierda, diríjase a la sección de
          <span className="fw-bold"> <FontAwesomeIcon icon={faMortarBoard} className={"fa-md"}/> Cursos</span>
          , que se mostrará la siguiente imagen. Debe identificar la facultad del curso. 
          Una vez identifiado, seleccione la facultad.
        </p>
        <PageImage
          src={addCourse1}
          title={""}
          alt={""}
        />
      </div>
  },

  {
    title: 
    <span className='text-uni'>
      <span className='fw-bold'>Paso 2: </span>
      Observe la lista de cursos
    </span>,
    content: 
      <div className="px-3 py-3">
        <p>
          Se desplegará una tabla con todos los cursos que están activos y archivados,
          si el curso se encuentra activo 
          <span className='fw-bold'> podrá editarlo (Editar)</span>, ver su información o 
          eliminarlo.
        </p>
        <PageImage
          src={modifyCourse2}
          title={""}
          alt={""}
        />
      </div>
  },



  {
    title: 
    <span className='text-uni'>
      <span className='fw-bold'>Paso 3: </span>
      Edite el curso
    </span>,
    content: 
      <div className="px-3 py-3">
        <p>
          Se desplegará una página con la infromacion del curso que podrá editar.
          La información que podrá editar es la siguiente:
        </p>

        <p className="fw-bold">
          Nota: La facultad no podrá modificarse.
        </p>

        <ListContent list={stepsAddCourseByFileData}/>
        <PageImage
          src={modifyCourse3}
          title={""}
          alt={""}
        />
      </div>
  },



  {
    title: 
    <span className='text-uni'>
      <span className='fw-bold'>Paso 4: </span>
        Presione el botón aceptar
    </span>,
    content: 
      <div className="px-3 py-3">
        <p>
          Presione el botón de aceptar para guardar la información editada.
          Si el formulario se editó correctamente, se mostrará un mensaje de confirmación.
        </p>
          
          <p className="fw-bold pt-2">
            Nota: Solo los administradores pueden editar información de todos los cursos. El 
            resto de usuarios únicamente podrá editar la información de aquellos cursos que
            pertenezcan a su facultad asignada.
          </p>
        <PageImage
          src={modifyCourse4}
          title={""}
          alt={""}
        />
      </div>
  },

  {
    title: 
    <span className='text-uni'>
      <span className='fw-bold'>Mensajes de errores </span>
    </span>,
    content: 
      <div className="px-3 py-3">
        <p>
          Cuando intente editar un curso y reciba un mensaje de error,
          esto puede deberse a diferentes motivos (conexión a internet, errores en los campos llenados del 
          formulario).
          Visite la sección de <span className="fw-bold">Preguntas frecuentes</span> para 
          obtener más información.
        </p>
      </div>
  },



  ]