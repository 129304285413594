import React, { useState, useEffect } from "react";
import _ from "lodash";
import useAxios from "./useAxios";
import { useLoading } from "./useLoading";

const useTable = ({
  endpoint = "",
  xParams = {},
  fakeElements = [],
  itemProperty = "items",
  initPageSize = 10,
  initPageNumber = 0,
}) => {
  const loading = useLoading();
  const [elements, setElements] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [pageSize, setPageSize] = useState(initPageSize);
  const [pageNumber, setPageNumber] = useState(initPageNumber);
  const [extraParams, setExtraParams] = useState(xParams);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState({});
  const { get } = useAxios();

  useEffect(() => {
    if (fakeElements.length > 0) {
      setElements(fakeElements);
    }
  }, []);

  useEffect(() => {
    search();
  }, [searchItem, extraParams, pageSize, pageNumber]);

  const getParams = () => {
    let params = {
      ...extraParams,
      limite: pageSize,
      desde: pageSize * pageNumber,
    };
    if (searchItem.length > 2) {
      params.search_item = searchItem;
    }
    return params;
  };

  const search = async () => {
    loading.setLoading(true);
    const params = getParams();

    try {
      const response = await get(endpoint, { params });
      const items = _.get(response, "data." + itemProperty, []);
      const total = _.get(response, "data.total", 0);
      if (response.data) {
        setElements(items);
        setTotal(total);
      }
    } catch (error) {
      // console.log({ error });
      setError(error);
    } finally {
      loading.setLoading(false);
    }
  };

  const onChangePageSize = (e) => {
    if (isNaN(Number(e.target.value))) throw new Error("Invalid page size");
    setPageSize(parseInt(e.target.value));
    setPageNumber(0);
  };

  const onChangePageNumber = (e, newPage) => {
    setPageNumber(newPage);
  };

  const onChangeSearchItem = (event) => {
    const value = event.target.value;
    setSearchItem(value);
  };

  const addExtraParams = (extra) =>
    setExtraParams({ ...extraParams, ...extra });

  const reset = () => {
    setSearchItem("");
    setExtraParams({});
  };

  const removeExtraParam = (key) => {
    const params = { ...extraParams };
    delete params[key];
    setExtraParams(params);
  };

  return {
    elements,
    searchItem,
    onChangeSearchItem,
    addExtraParams,
    extraParams,
    reset,
    search,
    removeExtraParam,
    onChangePageNumber,
    onChangePageSize,
    pageSize,
    pageNumber,
    total,
    error,
  };
};

export default useTable;
