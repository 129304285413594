import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const CardButton = ({ 
    index,
    col,
    path,
    className,
    classNameTitle,
    classNameIcon,
    icon,
    title 
}) => {
    
    return (
        <div key={index} className={col}>
            <Link to={path} className={className}>
                <FontAwesomeIcon icon={icon} className={classNameIcon} />
                <p className={classNameTitle}>
                    {title}
                </p>
            </Link>
        </div>
    )
}