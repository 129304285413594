import {
  faUserPlus,
  faUserLock,
  faUserGear,
  faFileMedical,
  faFileArrowUp,
  faFilePen,
  faFileExport,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { stepsAddUser } from "./info/stepsAddUser";
import { stepsModifyInfoUser } from "./info/stepsModifyInfoUser";
import { stepsRestorePassword } from "./info/stepsRestorePassword";
import { stepsAddCourse } from "./info/stepsAddCourse";
import { stepsAddCourseByFile } from "./info/stepsAddCourseByFile";
import { stepsModifyCourse } from "./info/stepsModifyCourse";
import { stepsExportDrupalAreas } from "./info/stepsExportDrupalAreas";
import { stepsExportDrupalCourses } from "./info/stepsExportDrupalCourses";
import { stepsExportDrupalExternal } from "./info/stepsExportDrupalExternal";
import { frequentQuestions } from "./info/frequentQuestions";

const iconStyle = "pe-2 fa-6x pb-3";
const classStyle = "btn btn-card btn-info pt-5 pb-3";

const videos = {
  "add-user": "https://drive.google.com/uc?export=view&id=12T70caVAQJ4-sUwTrcEBb5v78wNUok5R&confirm=t",
  "modify-user-info": "https://drive.google.com/uc?export=view&id=1zMhNxJ4eD3hqtTqPf6Dt-CVSqr_PcJKV&confirm=t",
  "restore-password": "https://drive.google.com/uc?export=view&id=1I4j5YqwDCybBsXWfuPuTH-iTHbHOx5ti&confirm=t",
  "add-course": "https://drive.google.com/uc?export=view&id=11cLwRIYE7_vdpFlOkUNHJeJJJSh4eNVt&confirm=t",
  "add-course-by-file": "https://drive.google.com/uc?export=view&id=1eBJJ9nxr9ZEle9Qc0kUew0U8AW7QjlNQ&confirm=t",
  "modify-course": "https://drive.google.com/uc?export=view&id=1Vi7v3-RxkG2dmJwJU0oIY0wfREzI80Uw&confirm=t",
  "export-to-drupal-areas": "https://drive.google.com/uc?export=view&id=170XFWOahSw49OWYiML1Iex11-Fo4LKya&confirm=t",
  "export-to-drupal-courses": "https://drive.google.com/uc?export=view&id=10Cz9h8Blex285wb1VUMIhRNFEO5qly_z&confirm=t",
  "export-to-drupal-external": "https://drive.google.com/uc?export=view&id=16E1C-IGTwPRhnUSVNWiYsZQM0Mg3AzhL&confirm=t",
}

export const HelpData = [
  {
    title: "Agregar un usuario",
    path: "add-user",
    visibleToUser: false,
    icon: faUserPlus,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">agregar un usuario</span></>,
      src: videos["add-user"],
    },
    steps: stepsAddUser,
  },
  {
    title: "Cambiar información del usuario",
    path: "modify-user-info",
    visibleToUser: false,
    icon: faUserGear,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">cambiar la información del usuario o 
      administrador</span></>,
      src: videos["modify-user-info"],
    },
    steps: stepsModifyInfoUser,
  },
  {
    title: "Reestablecer contraseña",
    path: "restore-password",
    visibleToUser: true,
    icon: faUserLock,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">reestablecer contraseña</span></>,
      src: videos["restore-password"],
    },
    steps: stepsRestorePassword,
  },
  {
    title: "Agregar un curso",
    path: "add-course",
    visibleToUser: true,
    icon: faFileMedical,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">agregar un nuevo curso</span></>,
      src: videos["add-course"],
    },
    steps: stepsAddCourse,
  },
  {
    title: "Agregar un curso por archivo",
    path: "add-course-by-file",
    visibleToUser: true,
    icon: faFileArrowUp,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">agregar un curso por archivo</span></>,
      src: videos["add-course-by-file"],
    },
    steps: stepsAddCourseByFile,
  },
  {
    title: "Modificar un curso",
    path: "modify-course",
    visibleToUser: true,
    icon: faFilePen,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">modificar un curso</span></>,
      src: videos["modify-course"],
    },
    steps: stepsModifyCourse,
  },
  {
    title: "Exportar a Drupal (Áreas académicas)",
    path: "export-to-drupal-areas",
    visibleToUser: false,
    icon: faFileExport,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">exportar a Drupal</span></>,
      src: videos["export-to-drupal-areas"],
    },
    steps: stepsExportDrupalAreas,
  },
  {
    title: "Exportar a Drupal (Cursos)",
    path: "export-to-drupal-courses",
    visibleToUser: false,
    icon: faFileExport,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">exportar a Drupal</span></>,
      src: videos["export-to-drupal-courses"],
    },
    steps: stepsExportDrupalCourses,
  },
  {
    title: "Exportar a Drupal (Cursos externos)",
    path: "export-to-drupal-external",
    visibleToUser: false,
    icon: faFileExport,
    classIcon: iconStyle,
    className: classStyle,
    video: {
      text: <>Instrucciones en video para <span className="fw-bold">exportar a Drupal</span></>,
      src: videos["export-to-drupal-external"],
    },
    steps: stepsExportDrupalExternal,
  },
  {
    title: "Preguntas frecuentes",
    path: "frequent-questions",
    visibleToUser: true,
    icon: faQuestion,
    classIcon: iconStyle,
    className: classStyle,
    steps: frequentQuestions,
  },
];

