import React from "react";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const Alert = (props) => {
  return (
    <Modal open={props.visible}>
      <Box sx={style}>

          <div className="modal-header pb-3">
            <h5 className="modal-title">{props.title}</h5>
            <button onClick={props.onCancel} className="btn-close"/>
          </div>
        
          <div className="modal-body background-text">
              {props.message}
          </div>
      
          <div className="modal-footer pt-3">
            {props.onCancel && !props.oneOption && (
              <button onClick={props.onCancel} className={`btn btn-${props.buttonColors.cancel} border text-button px-5 mx-1`}>
                Cancelar
              </button>
            )}
            {props.onAccept && (
              <button onClick={props.onAccept} className={`btn btn-${props.buttonColors.accept} border text-button px-5 mx-1`}>
                Aceptar
              </button>
            )}
          </div>
      </Box>
    </Modal>
  );
};

export default Alert;