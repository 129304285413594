import axios from "axios";
import _ from "lodash";

const URL = process.env.REACT_APP_BACKEND + process.env.REACT_APP_REST_API_ENPOINT;

const instance = axios.create({
  baseURL: URL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.token = token;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log({ error })
    if (error.response.status === 401) {
      // console.log('Refresh token')
      refreshToken();
    }
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    const token = localStorage.getItem("refresh");
    const response = await instance.post("/auth/refresh", {
      refresh: token,
    });
    const newToken = _.get(response, "data.token", "");
    if (newToken) localStorage.setItem("token", newToken);
    // console.log({ response });
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    // console.log({ error });
  } finally {
    window.location.reload();
  }
};

const useAxios = () => {
  return {
    get: instance.get,
    post: instance.post,
    put: instance.put,
    del: instance.delete,
  };
};

export default useAxios;

