import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LargeButton } from "../components/general/LargeButton";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageTitle } from "../components/general/PageTitle";
import { ActionButton } from "../components/table/ActionButton";
import { ActionButtonGroup } from "../components/table/ActionButtonGroup";
import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import useTable from "../hooks/useTable";
import { useForm } from "../hooks/useForm";
import { useEffect, useMemo, useState } from "react";
import { useFacultyInfo } from "../hooks/useFacultyInfo";
import { useLoading } from "../hooks/useLoading";
import { formatRole, obtainRole } from "../functions";
import useAlert from "../hooks/useAlert";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTimes, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { SimpleButton } from "../components/general/SimpleButton";
import { useUsersCRUD } from "../hooks/users/useUsersCRUD";
import { ExternalFacData } from "../data/categories/ExternalFacData";

const externalFacID = ExternalFacData.id;

const fields = [
    "Nombre",
    "Correo electrónico",
    "Rol",
    "Facultad",
    "Acciones",
];

export const FacultyUsersPage = () => {
    const { facID } = useParams();
    const facPart = facID ? "/" + facID : '';
    const navigate = useNavigate();
    const { faculty } = useFacultyInfo({ id: facID });

    const { loading: isLoading, setLoading } = useLoading();
    const { deleteUser, activateUser } = useUsersCRUD(true);
    const { openAlert } = useAlert();
    const usersTable = useTable({
        endpoint: "/usuarios",
        xParams: {
            facID,
            active: true,
        }
    });
    const { search, onInputChange } = useForm({ search: '' });
    const [showActive, setShowActive] = useState(true);

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Usuarios y administradores", path: "/users" },
            { name: faculty.name, path: `/users/${facID}` },
        ];
    }, [faculty, facID]);

    useEffect(() => {
        if (usersTable.error.response?.status) {
            const msg = _.get(usersTable.error, "response.data.errors[0].msg");
            openAlert(
                "Error al cargar usuarios",
                <>
                    Ocurrió un error al cargar los usuarios.&nbsp;
                    {msg
                        ? <b>{msg}</b>
                        : "Inténtelo de nuevo más tarde"}.
                </>,
                "error", false, null, null, 4000,
            )
        }
    }, [usersTable.error]);

    const onEdit = (id) => navigate(`/edit/user/${facID}/form/${id}`);
    const onView = (id) => navigate(`/user/${id}`);
    const onDelete = async (id, name, role) => {
        const resp = await openAlert(
            <>Eliminar {obtainRole(role)}</>,
            <>
                Está apunto de eliminar el {obtainRole(role)} "<b>{name}</b>".
                <br />
                ¿Desea hacerlo?
            </>,
        )
        if (resp) {
            const { ok, response, error } = await deleteUser(id);
            if (ok) {
                openAlert(
                    <>{obtainRole(role)} eliminado</>,
                    <>
                        El {obtainRole(role)} "<b>{name}</b>" se ha eliminado
                        correctamente.
                    </>,
                    "success", false, null, null, 4000,
                );
                setLoading(true);
                await usersTable.search();
                setLoading(false);
            } else {
                openAlert(
                    "Error en la eliminación",
                    <>
                        No se ha podido completar la eliminación.&nbsp;
                        {error
                            ? <b>{error}</b>
                            : "Inténtelo de nuevo más tarde"}.
                    </>,
                    "error", false, null, null, 4000,
                )
            }
        }
    };
    const onReactivate = async (id, name, role) => {
        const resp = await openAlert(
            `Reactivar ${obtainRole(role)}`,
            <>
                ¿Desea reactivar el {obtainRole(role)} "<b>{name}</b>"?
            </>,
            undefined, false, "info", undefined, undefined,
        )
        if (resp) {
            const { ok, response, error } = await activateUser(id);
            if (ok) {
                openAlert(
                    `${obtainRole(role)} reactivado`,
                    <>
                        El {obtainRole(role)} "<b>{name}</b>" se ha reactivado
                        correctamente.
                    </>,
                    "success", false, null, null, 4000,
                );
                setLoading(true);
                await usersTable.search();
                setLoading(false);
            } else {
                openAlert(
                    "Error al reactivar",
                    <>
                        No se ha podido completar la reactivacion.&nbsp;
                        {error
                            ? <b>{error}</b>
                            : "Inténtelo de nuevo más tarde"}.
                    </>,
                    "error", false, null, null, 4000,
                )
            }
        }
    };

    const onSubmitSearch = (event) => {
        event.preventDefault();

        const value = search.trim();
        usersTable.removeExtraParam('nombre');
        if (value.length > 0) {
            usersTable.addExtraParams({ nombre: value });
        }
    }

    const onSwitchActive = () => {
        usersTable.removeExtraParam('active');
        usersTable.addExtraParams({ active: !showActive });
        setShowActive(!showActive);
    };

    return (
        <PageWithSidebar links={links}>

            <div className="d-flex justify-content-between">
                <PageTitle>Lista de administradores y usuarios</PageTitle>
                <SimpleButton
                    icon={<FontAwesomeIcon icon={showActive ? faUserTimes : faUserCheck} />}
                    value={<>&nbsp;Ver usuarios {showActive ? 'inactivos' : 'activos'}</>}
                    onClick={onSwitchActive}
                    hideTextWhenBreakpoint
                    className="my-3"
                    classType="primary"
                />
            </div>

            <PageParagraph>
                {facID !== externalFacID
                    ? <>Lista de usuarios y administradores {!showActive && <b>inactivos</b>} de la {faculty.name}.</>
                    : <>Lista de usuarios y administradores {!showActive && <b>inactivos</b>} de {faculty.name}.</>}
            </PageParagraph>

            <Link to={`/add/user${facPart}`}>
                <LargeButton value="+ Agregar usuario" />
            </Link>

            <Table
                fields={fields}
                total={usersTable.total}
                pageSize={usersTable.pageSize}
                pageNumber={usersTable.pageNumber}
                onChangePageSize={usersTable.onChangePageSize}
                onChangePageNumber={usersTable.onChangePageNumber}
                searchValue={search}
                onChangeSearchValue={onInputChange}
                onSubmitSearchValue={onSubmitSearch}
            >
                {usersTable.elements.map((el) => (
                    <TableRow
                        key={el.uid}
                        values={[
                            el.nombre,
                            el.correo,
                            formatRole(el.rol),
                            _.get(el, "facultad.facultad", ""),
                            <ActionButtonGroup>

                                {showActive
                                    ? <>
                                        <ActionButton
                                            key="btn-view"
                                            value="Ver"
                                            onClick={() => onView(el.uid)}
                                        />
                                        <ActionButton
                                            key="btn-edit"
                                            value="Editar"
                                            onClick={() => onEdit(el.uid)}
                                        />
                                        <ActionButton
                                            key="btn-delete"
                                            value="Borrar"
                                            onClick={() => onDelete(el.uid, el.nombre, el.rol)}
                                            type="danger"
                                        />
                                    </>
                                    : <ActionButton
                                        key="btn-unarchive"
                                        value="Reactivar"
                                        colXXL={12}
                                        onClick={() => onReactivate(el.uid, el.nombre, el.rol)}
                                        type="secondary"
                                        disabled={isLoading}
                                        className='text-white'
                                    />
                                }
                            </ActionButtonGroup>,
                        ]}
                    />
                ))}
            </Table>

        </PageWithSidebar>
    );
}