import { useEffect, useMemo, useState } from "react";
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faFileLines } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate, useParams } from "react-router-dom";
import { LargeButton } from "../components/general/LargeButton";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageTitle } from "../components/general/PageTitle";
import { ActionButton } from "../components/table/ActionButton";
import { ActionButtonGroup } from "../components/table/ActionButtonGroup";
import { Table } from "../components/table/Table";
import { TableRow } from "../components/table/TableRow";
import useTable from "../hooks/useTable";
import { useForm } from "../hooks/useForm";
import { formatToSimpleDate, formatToUsableDate } from "../functions";
import { useFacultyInfo } from "../hooks/useFacultyInfo";
import useAxios from "../hooks/useAxios";
import { useLoading } from "../hooks/useLoading";

import useAlert from "../hooks/useAlert";
import { useRedirectIfForbidden } from "../hooks/useRedirectIfForbidden";
import { SimpleButton } from "../components/general/SimpleButton";
import { useCoursesCRUD } from "../hooks/courses/useCoursesCRUD";
import useMyInfo from "../hooks/useMyInfo";
import { roleValues } from "../data/role";
import { ExternalFacData } from "../data/categories/ExternalFacData";

const externalFacID = ExternalFacData.id;

const fields = [
    'Título',
    'Registro Institucional',
    'Fecha de inicio y terminación',
    'Costo',
    'Publicado',
    'Acciones',
];

export const FacultyCoursesPage = () => {
    const { facID } = useParams();
    const facPart = facID ? "/" + facID : '';
    const navigate = useNavigate();
    const { faculty } = useFacultyInfo({ id: facID });
    const user = useMyInfo();

    const { redirectIfForbidden } = useRedirectIfForbidden();

    const { loading: isLoading, setLoading } = useLoading();
    const { deleteCourse, unarchiveCourse } = useCoursesCRUD(true);
    const { openAlert } = useAlert();

    const table = useTable({
        endpoint: "/cursos",
        xParams: {
            facultad: facID,
            active: true,
        }
    });
    const { search, onInputChange } = useForm({ search: '' });
    const [showActive, setShowActive] = useState(true);

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Cursos", path: "/courses" },
            { name: faculty.name, path: `/courses/${facID}` },
        ];
    }, [faculty, facID]);

    useEffect(() => {
        if (table.error.response?.status === 403) {
            checkIfForbidden();
        } else if (table.error.response?.status) {
            const msg = _.get(table.error, "response.data.errors[0].msg");
            openAlert(
                "Error al cargar cursos",
                <>
                    Ocurrió un error al cargar los cursos.&nbsp;
                    {msg
                        ? <b>{msg}</b>
                        : "Inténtelo de nuevo más tarde"}.
                </>,
                "error", false, null, null, 4000,
            )
        }
    }, [table.error]);

    const checkIfForbidden = async () => {
        if (user.isUserLoading) await user.getMyInfo();
        if (user.rol === roleValues.USER_ROLE && user.facultad_id !== facID) {
            redirectIfForbidden('/courses', true);
        }
    }

    const onEdit = (id) => navigate(`/edit/course/${facID}/form/${id}`);
    const onView = (id) => navigate(`/course/${id}`);
    const onDelete = async (id, title) => {
        const resp = await openAlert(
            "Eliminar curso",
            <>
                Está apunto de eliminar el curso "<b>{title}</b>".
                <br />
                ¿Desea hacerlo?
            </>,
        )
        if (resp) {
            const { ok, response, error } = await deleteCourse(id);
            if (ok) {
                openAlert(
                    "Curso eliminado",
                    <>
                        El curso "<b>{title}</b>" se ha eliminado
                        correctamente.
                    </>,
                    "success", false, null, null, 4000,
                );
                setLoading(true);
                await table.search();
                setLoading(false);
            } else {
                openAlert(
                    "Error en la eliminación",
                    <>
                        No se ha podido completar la eliminación.&nbsp;
                        {error
                            ? <b>{error}</b>
                            : "Inténtelo de nuevo más tarde"}.
                    </>,
                    "error", false, null, null, 4000,
                )
            }
        }
    };
    const onUnarchive = async (id, title) => {
        const resp = await openAlert(
            "Desarchivar curso",
            <>
                ¿Desea desarchivar el curso "<b>{title}</b>"?
            </>,
            undefined, false, "info", undefined, undefined,
        )
        if (resp) {
            const { ok, response, error } = await unarchiveCourse(id);
            if (ok) {
                openAlert(
                    "Curso desarchivado",
                    <>
                        El curso "<b>{title}</b>" se ha desarchivado
                        correctamente.
                    </>,
                    "success", false, null, null, 4000,
                );
                setLoading(true);
                await table.search();
                setLoading(false);
            } else {
                openAlert(
                    "Error al desarchivar",
                    <>
                        No se ha podido completar el desarchivado.&nbsp;
                        {error
                            ? <b>{error}</b>
                            : "Inténtelo de nuevo más tarde"}.
                    </>,
                    "error", false, null, null, 4000,
                )
            }
        }
    };

    const onSubmitSearch = (event) => {
        event.preventDefault();

        const value = search.trim();
        table.removeExtraParam('nombre');
        if (value.length > 0) {
            table.addExtraParams({ nombre: value });
        }
    }

    const onSwitchActive = () => {
        table.removeExtraParam('active');
        table.addExtraParams({ active: !showActive });
        setShowActive(!showActive);
    };

    return (
        <PageWithSidebar links={links}>

            <div className="d-flex justify-content-between">
                <PageTitle>Lista de cursos</PageTitle>
                <SimpleButton
                    icon={<FontAwesomeIcon icon={showActive ? faArchive : faFileLines} />}
                    value={<>&nbsp;Ver cursos {showActive ? 'archivados' : 'activos'}</>}
                    onClick={onSwitchActive}
                    hideTextWhenBreakpoint
                    className="my-3"
                    classType="primary"
                />
            </div>

            <PageParagraph>
                {facID !== externalFacID
                    ? <>Lista de cursos {!showActive && <b>archivados</b>} de la {faculty.name}.</>
                    : <>Lista de cursos {!showActive && <b>archivados</b>} de {faculty.name}.</>}
            </PageParagraph>

            <Link to={`/add/course${facPart}/options`}>
                <LargeButton value="+ Agregar curso" />
            </Link>

            <Table
                fields={fields}
                total={table.total}
                pageSize={table.pageSize}
                pageNumber={table.pageNumber}
                onChangePageSize={table.onChangePageSize}
                onChangePageNumber={table.onChangePageNumber}
                searchValue={search}
                onChangeSearchValue={onInputChange}
                onSubmitSearchValue={onSubmitSearch}
            >
                {table.elements.map((c) => (
                    <TableRow
                        key={c._id}
                        values={[
                            c.titulo,
                            c.registro_institucional,
                            `${formatToSimpleDate(formatToUsableDate(c.fecha_inicio))} - ${formatToSimpleDate(formatToUsableDate(c.fecha_final))}`,
                            ` $ ${c.precio ?? 0} MXN`,
                            c.publicado ? "Sí" : "No",
                            <ActionButtonGroup>
                                {showActive
                                    ? <>
                                        <ActionButton
                                            key="btn-view"
                                            value="Ver"
                                            onClick={() => onView(c._id)}
                                            disabled={isLoading}
                                            className='text-white'
                                        />
                                        <ActionButton
                                            key="btn-edit"
                                            value="Editar"
                                            onClick={() => onEdit(c._id)}
                                            disabled={isLoading}
                                            className='text-white'
                                        />
                                        <ActionButton
                                            key="btn-delete"
                                            value="Borrar"
                                            onClick={() => onDelete(c._id, c.titulo)}
                                            type="danger"
                                            disabled={isLoading}
                                            className='text-white'
                                        />
                                    </>
                                    : <ActionButton
                                        key="btn-unarchive"
                                        value="Desarchivar"
                                        colXXL={12}
                                        onClick={() => onUnarchive(c._id, c.titulo)}
                                        type="secondary"
                                        disabled={isLoading}
                                        className='text-white'
                                    />
                                }
                            </ActionButtonGroup>,
                        ]}
                    />
                ))}
            </Table>

        </PageWithSidebar>
    );
}
