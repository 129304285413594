import { useEffect, useState } from "react";

export const useForm = (
    initialForm = {},           // Entradas iniciales del formulario
    initialSchema,              // Schema inicial del formulario (para validar)
    validateOnChange = true,    // Indica si puede validar por cambio (por defecto es true)
) => {

    // Este almacena los campos del formulario
    const [formState, setFormState] = useState(initialForm);

    // Este almacena el estado inicial del schema para mantenerlo íntegro.
    const [schema, setSchema] = useState(initialSchema);

    // Este solo indica cuando ya ha sido "submited" el formulario
    const [isSubmited, setIsSubmited] = useState(false);
    
    // Este tiene las propiedades del schema y en ellas indica el mensaje de error
    const [errors, setErrors] = useState({});

    const onInputChange = (event) => {
        const { target: { name, value } } = event;
        setFormState({ ...formState, [name]: value });
    }

    const onResetForm = () => setFormState(initialForm);

    const putErrors = (errorList = []) => {
        const errorState = {};
        for (let v of errorList) {
            errorState[v.path] = v.message;
        }
        setErrors(errorState);
    }

    // Este método se debería llamar cada vez que se quiera validar el formulario
    const validateFormState = () => {
        if (!!schema) {
            try {
                schema.validateSync(formState, { abortEarly: false });
                putErrors([]);
                return true;
            } catch (error) {
                putErrors(error.inner);
                return false;
            }
        }
        return true;
    }

    // Este método se debería llamar en el momento en que se haga submit
    const hasBeenSubmited = (value = true) => {
        setIsSubmited(value);
    }

    useEffect(() => {
        if (isSubmited && !!schema && validateOnChange) {
            validateFormState();
        }
    }, [formState]);

    return {
        // Values
        ...formState,
        formState,
        errors,
        
        // Functions
        onInputChange,
        onResetForm,
        setFormState,
        validateFormState,
        hasBeenSubmited,
    }
}
