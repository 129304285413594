import { PageTitle } from "../components/general/PageTitle";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { BodyContainer } from "../components/general/BodyContainer";
import { LargeButton } from "../components/general/LargeButton";
import imgUser from "../assets/img/default-user-image.png";
import { useNavigate } from "react-router-dom";
import useMyInfo from "../hooks/useMyInfo";

const links = [{ name: "Inicio", path: "/" }];

export const HomePage = (props) => {
  const user = useMyInfo();
  const navigate = useNavigate();

  return (
    <PageWithSidebar links={links}>
      <PageTitle>Le damos la bienvenida</PageTitle>

      <PageParagraph>
        Esta es una herramienta para administrar los <b>programas</b> que
        serán publicados en la página de <b>Educación Continua</b>.
      </PageParagraph>
      <PageParagraph>
        Para comenzar, acceda a la pestaña llamada "<b>Cursos</b>" en donde
        podrá encontrar todas las facultades registradas por la BUAP. En cada
        una podrá crear cursos y diplomados para posteriormente agregarlos a
        la página principal.
      </PageParagraph>
      <PageParagraph>
        Si necesita una guía para poder comenzar, puede acceder a la sección
        de "<b>Ayuda</b>".
      </PageParagraph>

      <BodyContainer>
        <div className="row g-0">
          <div className="col-md-4" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <img
              src={user.img || imgUser}
              className="img-fluid img-border img-user-size align-middle"
              alt="user"
            />
          </div>
          <div className="col-md-8">
            <div className="px-4 py-4">
              <h5 className="card-title pb-4">{user.nombre}</h5>
              <p className="card-text">
                <span className="fw-bold">Correo electrónico: </span>
                {user.correo}
              </p>
              <p className="card-text">
                <span className="fw-bold">Rol o cargo: </span>
                {user.rol}
              </p>
              <p className="card-text">
                <span className="fw-bold">Facultad: </span>
                {user.facultad}
              </p>
            </div>
          </div>
        </div>
      </BodyContainer>

      <LargeButton
        value="Editar perfil"
        onClick={() => navigate(`/edit/user/${user.facultad_id}/form/${user.id}`)}
      />
    </PageWithSidebar>
  );
};
