import { PageImage } from "../../../../components/general/PageImage";
import { ListContent } from '../../../../components/general/ListContent'


import addUser2_1 from '../../../../assets/img/addUser/addUser2.2.1.png'
import addUser2_2 from '../../../../assets/img/addUser/addUser2.2.2.png'


export const stepsAddUserData1 = [
    {
      title: <span className="fw-bold">Nombre completo de la persona (obligatorio)</span>,
      content: <span>Nombres, Apellido Paterno y Apellido Materno.</span>,
    },
    {
      title: <span className="fw-bold">Correo electrónico (obligatorio)</span>,
      content: <span>
        Debe ser un correo único sin haber sido registrado en el 
        sistema anteriormente. La dirección de correo debe existir, ya que este será el
        medio por el cual el usuario o administrador recibirá su contraseña de acceso.
      </span>,
    },
    {
      title: <span className="fw-bold">Rol (obligatorio)</span>,
      content: <span>
        Puede ser Usuario o Administrador. Dependiendo del rol que se le asigne, serán los 
        permisos y accesos que tendrá.
      </span>,
    },
    {
      title: <span className="fw-bold">Facultad asignada (obligatoria)</span>,
      content: <span>
        Puede elegir cualquier facultad. Dependiendo de la facultad, serán los cursos que administrará.
      </span>,
    },
    {
      title: <span className="fw-bold">Imagen del usuario o administrador</span>,
      content: <span>
        Una fotografía del administrador o usuario. Si no se selecciona una imagen, se asignará
        una por defecto.
      </span>,
    },
  ];


  export const stepsAddUserData2_2 = [
    {
      title:
        <span className='text-uni'>
          <span className='fw-bold'>Paso 2.1: </span>Observe los usuarios de la facultad seleccionada
        </span> 
      ,
      content: 
          <div className="px-3 py-3">
            <p>
              Al presionar el botón <span className='fw-bold'> + Agregar usuario</span>,
              se mostrará el formulario para agregar un usuario o administrador 
              (el usuario o administrador se registrará para la facultad seleccionada).
            </p>
            <PageImage
              src={addUser2_1}
              title={""}
              alt={""}
            />
          </div>
        ,
    },
    {
      title:
        <span className='text-uni'>
          <span className='fw-bold'>Paso 2.2: </span>Acceda al formulario para agregar un usuario o administrador
        </span>
      ,
      content: 
          <div className="px-3 py-3">
            <p>
              Para agregar un nuevo usuario o administrador con el formulario necesita:
            </p>
            <ListContent list={stepsAddUserData1}/>
            <PageImage
              src={addUser2_2}
              title={""}
              alt={""}
            />
          </div>
      ,
    },
  ];