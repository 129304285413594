
export const LargeButton = ({ value = 'Botón largo', classType = "info", type = 'button', marginBottom = true, onClick }) => {

    return (
        <div className={`d-grid gap-2 ${marginBottom ? 'mb-3' : ''}`}>
            <button
                className={`btn btn-${classType} text-button border-0`}
                type={type}
                onClick={onClick}
            >
                {value}
            </button>
        </div>
    );
}
