import { SearchBar } from "./SearchBar";
import { Pagination } from "./TablePagination";

export const Table = ({
  fields = ["something", "goes", "here"],
  children = null,
  total = 10,
  pageSize = 10,
  pageNumber = 1,
  searchValue = "",
  onChangePageSize = () => {},
  onChangePageNumber = () => {},
  onSubmitSearchValue = () => {},
  onChangeSearchValue = () => {},
  displayDatePicker = false,
  dateLabel = "",
  dateValue = "",
  onChangeDate = () => {},
}) => {
  return (
    <>
      <SearchBar
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
        onSubmitSearchValue={onSubmitSearchValue}
        total={total}
        dateLabel={dateLabel}
        dateValue={dateValue}
        displayDatePicker={displayDatePicker}
        onChangeDate={onChangeDate}
      />

      <div className="scroll-table">
        <table className="table table-bordered">
          <thead className="table-secondary">
            <tr>
              {fields.map((field) => (
                <th key={`${field}-key`} scope="col">
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="background-tbody">{children}</tbody>
        </table>
      </div>

      <Pagination
        total={total}
        pageNumber={pageNumber}
        pageSize={pageSize}
        onChangePageSize={onChangePageSize}
        onChangePageNumber={onChangePageNumber}
      />
    </>
  );
};
