
export const SimpleButton = ({
    icon,
    value = 'Botón',
    classType = "info",
    className = '',
    type = 'button',
    hideTextWhenBreakpoint = false,
    hideTextBreakpoint = "sm",
    onClick
}) => {

    return (
        <button
            className={`btn btn-${classType} text-button border-0 ${className}`}
            type={type}
            onClick={onClick}
        >
            {icon}
            <div className={hideTextWhenBreakpoint ? `d-none d-${hideTextBreakpoint}-inline` : ''}>{value}</div>
        </button>
    );
}

