import useTable from "../hooks/useTable";
import { useForm } from "../hooks/useForm";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { PageTitle } from "../components/general/PageTitle";
import { PageParagraph } from "../components/general/PageParagraph";
import { LargeButton } from "../components/general/LargeButton";
import { Link } from "react-router-dom";
import { FacultyTable } from "../components/faculties/FacultyTable";
import { useMemo } from "react";

const links = [
  { name: "Inicio", path: "/" },
  { name: "Usuarios y administradores", path: "/users" },
];

export const UsersPage = (props) => {
  const table = useTable({
    endpoint: "/public/facultades",
    initPageSize: 100,
    // itemProperty: 'facultades'
  });

  const { search, onInputChange } = useForm({ search: '' });

  const onSubmitSearch = (event) => {
    event.preventDefault();

    const value = search.trim();
    table.removeExtraParam('facultad');
    if (value.length > 0) {
      table.addExtraParams({facultad: value});
    }
  }

  const facItems = useMemo(() => {
    return table.elements.map(i => ({
      id: i._id,
      name: i.facultad,
      category: i.categoria_id,
    }));
  }, [table.elements]);

  return (

    <PageWithSidebar links={links}>

      <PageTitle>Usuarios y administradores por facultad</PageTitle>
      <PageParagraph>
        Puede seleccionar una facultad para administrar sus administradores y usuarios, o bien,
        puede agregar uno directamente.
      </PageParagraph>

      <Link to={`/add/${'user'}`}>
        <LargeButton value="+ Agregar usuario" />
      </Link>

      <div>
        <PageParagraph>
          Seleccione una facultad.
        </PageParagraph>

        <FacultyTable
          // faculties={table.elements}
          faculties={facItems}
          total={facItems.length}
          searchValue={search}
          path = {'users'}
          onChangeSearchValue={onInputChange}
          onSubmitSearchValue={onSubmitSearch}
        />
      </div>

    </PageWithSidebar>
  );
};
