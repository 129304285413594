import * as yup from 'yup';

const MAX_FILE_SIZE = 5000000;
const MAX_STRING_LENGTH = 255;

export const userSchema = yup.object({
    name: yup.string()
        .required('El nombre es obligatorio')
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    role: yup.string()
        .notOneOf(['none'], 'Seleccione una opción válida')
        .required('El rol es obligatorio'),
    faculty: yup.string()
        .notOneOf(['none'], 'Seleccione una opción válida')
        .required('La facultad es obligatoria'),
    email: yup.string()
        .email('El correo electrónico no es válido')
        .required('El correo electrónico es obligatorio')
        .max(MAX_STRING_LENGTH, `El campo puede tener como máximo ${MAX_STRING_LENGTH} caracteres`),
    image: yup.mixed()
        .test("fileSize", "El archivo es muy grande (mayor que 5 MB)", (value) => {
            if (value) return value.size <= MAX_FILE_SIZE;
            return true;
        }),
});