import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleDown,
    faAngleUp,
  } from "@fortawesome/free-solid-svg-icons";

export const Accordion = ({ 
    questions, 
}) => {
    
    const [show, setShow] = useState(null);

    const selected = (index) => {
        show == index ? setShow(null) : setShow(index);
    }
    
    return (
        <div className="wrapper">
            <div className="accordion">
              {questions.map((item,index) => {
                  return (
                    <div key={index} className="item">
                      <div className="title" onClick={()=>selected(index)}>
                          {item.title}
                        <span>
                            {show === index ? 
                            <FontAwesomeIcon icon={faAngleUp} className={"fa-lg"} /> 
                            : 
                            <FontAwesomeIcon icon={faAngleDown} className={"fa-lg"} />}
                        </span>
                      </div>
                      <div className= {show === index ? 'content show' : 'content'}>
                        {item.content}
                      </div>
                    </div>
                  )
                })}
            </div>
        </div>
    )
}