import React, { useState } from "react";
import useAxios from "../hooks/useAxios";
import { useNavigate } from "react-router";
import useAlert from "../hooks/useAlert";
import _ from 'lodash';

import { useLoading } from "../hooks/useLoading";
import { FormTextInput } from "../components/form/FormTextInput"
import { PageWithCenteredSquare } from "../components/general/PageWithCenteredSquare";
import { forgotPassSchema } from "../schemas/forgotPassSchema";
import { useForm } from "../hooks/useForm";
import { PageParagraph } from "../components/general/PageParagraph";
import { LargeButton } from "../components/general/LargeButton";

export const ForgotPasswordPage = (props) => {

  const {
    email,
    onInputChange,
    errors,
    validateFormState,
    hasBeenSubmited
  } = useForm(
    {
      email: "",
    },
    forgotPassSchema,
  );
  const navigate = useNavigate();
  const { put } = useAxios();
  const { openAlert } = useAlert();
  const loading = useLoading();

  const handleSubmit = async (event) => {
    event.preventDefault();

    hasBeenSubmited();
    const isValid = validateFormState();
    if (!isValid) {
      openAlert(
        "Errores en el formulario",
        <>
          Por favor revise el campo del formulario
          e inténtelo nuevamente.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
      return;
    }

    try {
      loading.setLoading(true);
      const response = await put("/auth/forgot_password", { correo: email });
      if (response.data && response.data.msg) {
        await openAlert(
          "Correo enviado",
          <>
            Se ha enviado un link para reestablecer la contraseña
            al correo ingresado.
          </>,
          undefined, true, "info", undefined, undefined,
        );
        navigate("/login", { replace: true });
      }
    } catch (error) {
      const message = _.get(error, "response.data.errors[0].msg");
      openAlert(
        "Error al enviar correo",
        <>
          Ocurrió un error al intentar enviar el correo.<br />
          {message
            ? <b>{message}</b>
            : "Inténtelo de nuevo más tarde"}.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
      // console.log({ error });
    } finally {
      loading.setLoading(false);
    }
  };

  return (
    <PageWithCenteredSquare>
      <form onSubmit={handleSubmit} className="needs-validation" noValidate>

        <h5 className="mt-2 text-uni">Reestablecer contraseña</h5>

        <div className="py-2">
          <PageParagraph>
            Para poder iniciar el cambio de contraseña, ingrese su dirección de
            correo electrónico.
          </PageParagraph>
          <PageParagraph>
            Haga click en enviar correo, y después siga los pasos que se
            encuentran en este.
          </PageParagraph>

          <FormTextInput
            label="Correo electrónico"
            name="email"
            value={email}
            type="text"
            required={true}
            onChange={onInputChange}
            placeholder='correo@electronico.mx'
            invalidFeedback={errors.email}
          />
        </div>

        <div className="pt-2">
          <LargeButton value="Enviar correo" classType="primary" type="submit" />
        </div>
      </form>
    </PageWithCenteredSquare>
  );
};
