import { socket } from "../socket/instance";

export const useSocket = () => {

    const on = (event, listener) => {
        socket.on(event, listener);
    }

    const off = (event, listener) => {
        socket.off(event, listener);
    }

    const emit = (event, message = "") => {
        socket.emit(event, message);
    }

    return {
        socket,
        on,
        off,
        emit,
    }
}
