import { faHeartPulse, faHelmetSafety, faHandsHoldingCircle, faClipboardList, faGlobe } from "@fortawesome/free-solid-svg-icons"

export const CategoryIconData = [
    {
        id: '62cf5520bfbd4d9f7be1c88b',
        icon: faHeartPulse,
    },
    {
        id: '62cf554cbfbd4d9f7be1c88c',
        icon: faHelmetSafety,
    },
    {
        id: '62cf5571bfbd4d9f7be1c88d',
        icon: faHandsHoldingCircle,
    },
    {
        id: '62cf5595bfbd4d9f7be1c88e',
        icon: faClipboardList,
    },
    {
        id: '62e0af0efcc7040d40737c90',
        icon: faGlobe,
    },
]

export const getIconByCategory = (id) => CategoryIconData.find(category => category.id === id)?.icon;