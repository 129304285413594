import { Navigate, Route, Routes } from "react-router-dom";

import { HomePage } from "../pages/HomePage";
import { FacultyUsersPage } from "../pages/FacultyUsersPage";
import { UsersPage } from "../pages/UsersPage";
import { ModifyPasswordPage } from "../pages/ModifyPasswordPage";
import { AddUserPage } from "../pages/AddUserPage";
import { DetailsUserPage } from "../pages/DetailsUserPage";
import { CoursesPage } from "../pages/CoursesPage";
import { FacultyCoursesPage } from "../pages/FacultyCoursesPage";
import { DetailsCoursePage } from "../pages/DetailsCoursePage";
import { AddCourseOptionsPage } from "../pages/AddCourseOptionsPage";
import { AddCoursePage } from "../pages/AddCoursePage";
import { AddCourseByFilePage } from "../pages/AddCoursePageByFile";
import { ExportsPage } from "../pages/ExportsPage";
import { ExportViewPage } from "../pages/ExportViewPage";
import { HelpPage } from "../pages/HelpPage";
import { HelpWithPage } from "../pages/HelpWithPage";
import logo from "../assets/img/logo-buap-white.png";
import useMyInfo from "../hooks/useMyInfo";
import { roleValues } from "../data/role";
import { createRedirect, createRoute } from "../functions";

export const perms = {
  ALL_USERS: [
    roleValues.USER_ROLE,
    roleValues.ADMIN_ROLE,
    roleValues.SUPER_ADMIN,
  ],
  ONLY_ADMINS: [roleValues.ADMIN_ROLE, roleValues.SUPER_ADMIN],
  ONLY_USER: [roleValues.USER_ROLE],
};

const { ALL_USERS, ONLY_ADMINS, ONLY_USER } = perms;

export const routes = [
  createRoute("/", HomePage, ALL_USERS),

  createRoute("/users", UsersPage, ONLY_ADMINS),
  createRoute("/users/:facID", FacultyUsersPage, ONLY_ADMINS),
  createRoute("/user/:userID", DetailsUserPage, ONLY_ADMINS),
  createRoute("/add/user", AddUserPage, ONLY_ADMINS),
  createRoute("/add/user/:facID", AddUserPage, ONLY_ADMINS),
  createRoute("/edit/user/:facID/form/:userID", AddUserPage, ALL_USERS),
  createRoute("/user/restore", ModifyPasswordPage, ALL_USERS),

  createRoute("/courses", CoursesPage, ONLY_ADMINS),
  createRoute("/courses/:facID", FacultyCoursesPage, ALL_USERS),
  createRoute("/course/:courseID", DetailsCoursePage, ALL_USERS),
  createRoute("/add/course/options", AddCourseOptionsPage, ONLY_ADMINS),
  createRoute("/add/course/form", AddCoursePage, ONLY_ADMINS),
  createRoute("/add/course/by-file", AddCourseByFilePage, ONLY_ADMINS),
  createRoute("/add/course/:facID/options", AddCourseOptionsPage, ALL_USERS),
  createRoute("/add/course/:facID/form", AddCoursePage, ALL_USERS),
  createRoute("/add/course/:facID/by-file", AddCourseByFilePage, ALL_USERS),
  createRoute("/edit/course/:facID/form/:courseID", AddCoursePage, ALL_USERS),

  createRoute("/exports", ExportsPage, ONLY_ADMINS),
  createRoute("/exports/:exportID", ExportViewPage, ONLY_ADMINS),

  createRoute("/help", HelpPage, ALL_USERS),
  createRoute("/help/:helpID", HelpWithPage, ALL_USERS),
];

const buildRedirects = ({ user }) => [
  createRedirect("/courses", `/courses/${user.facultad_id}`, ONLY_USER),
  createRedirect(
    "/add/course/options",
    `/add/course/${user.facultad_id}/options`,
    ONLY_USER
  ),
  createRedirect(
    "/add/course/form",
    `/add/course/${user.facultad_id}/form`,
    ONLY_USER
  ),
  createRedirect(
    "/add/course/by-file",
    `/add/course/${user.facultad_id}/by-file`,
    ONLY_USER
  ),
  createRedirect("/*", "/", ALL_USERS),
];

export const CheckUserRoutes = () => {
  const user = useMyInfo();
  const redirects = buildRedirects({ user });

  return (
    user.rol
      ? <Routes>
          {routes
            .filter((route) => route.roles.includes(user.rol))
            .map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact
                element={<route.element />}
              />
            ))}
          {redirects
            .filter((route) => route.roles.includes(user.rol))
            .map((redirect, index) => (
              <Route
                key={`${index}-redirect`}
                path={redirect.path}
                exact
                element={<Navigate to={redirect.redirectTo} />}
              />
            ))}
        </Routes>
      : <div className="nothing">
          <img src={logo} alt="imagen logo" />
          Espere unos momentos...
        </div>
  );
};
