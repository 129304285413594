import { createContext, useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import _ from 'lodash';
import useAlert from "../hooks/useAlert";

export const FacultiesContext = createContext({});

const initialValue = [
    { facultad: '-----', _id: '-----' },
]

export const FacultiesProvider = ({ children }) => {

    const [faculties, setFaculties] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(false);
    const { openAlert } = useAlert();
    const { get } = useAxios();

    useEffect(() => {
        loadFaculties();
    }, []);

    const loadFaculties = async () => {
        try {
            setIsLoading(true);
            const resp = await get(`public/facultades`);
            if (resp.status === 200) {
                const result = resp.data.items;
                setFaculties(result);
                return;
            }
            // console.log(resp);
        } catch (error) {
            const message = _.get(error, "response.data.errors[0].msg");
            openAlert(
                "Error al cargar facultades",
                <>
                    Ocurrió un error al cargar la lista de facultades.&nbsp;
                    {message
                        ? <b>{message}</b>
                        : "Inténtelo de nuevo más tarde"}.
                </>,
                undefined, true, "secondary", undefined, undefined,
            );
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <FacultiesContext.Provider value={{ faculties, isLoading, loadFaculties }}>
            {children}
        </FacultiesContext.Provider>
    );
}
