


export const stepsAddUserData2 = [
    {
      title: <span className="fw-bold">Nombre completo de la persona (obligatorio)</span>,
      content: <span>Nombres, Apellido Paterno y Apellido Materno.</span>,
    },
    {
      title: <span className="fw-bold">Correo electrónico (obligatorio)</span>,
      content: <span>
        Debe ser un correo único sin haber sido registrado en el 
        sistema anteriormente. La dirección de correo debe existir, ya que este será el
        medio por el cual el usuario o administrador recibirá su contraseña de acceso.
      </span>,
    },
    {
      title: <span className="fw-bold">Rol (obligatorio)</span>,
      content: <span>
        Puede ser Usuario o Administrador. Dependiendo del Rol que se le asigne serán los 
        permisos y accesos que tendra.
      </span>,
    },
    {
      title: <span className="fw-bold">Facultad (obligatoria)</span>,
      content: <span>
        Se le asignará la facultad y no podrá modificarse.
      </span>,
    },
    {
      title: <span className="fw-bold">Imagen del usuario o administrador</span>,
      content: <span>
        Una fotografía del administrador o usuario. Si no se selecciona una imagen se asignará
        una por defecto.
      </span>,
    },
  ];