
export const FormFileInput = ({
    name,
    accept,
    onChange,
    required = false,
    disabled = false,
    validFeedback,
    invalidFeedback,
}) => {
    
    const display = invalidFeedback?.length > 0 ? 'd-block' : 'd-none';
    
    return (
        <div className="py-2">
            <input 
                type='file'
                id={name}
                name={name}
                accept={accept}
                required={required}
                className="form-control"
                onChange={onChange}
                disabled={disabled}
            />
                <div className="valid-feedback">
                    {validFeedback}
                </div>
                <div className={`invalid-feedback ${display}`}>
                    {invalidFeedback}
                </div>
        </div>
    )
}