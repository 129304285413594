export const roles = {
  SUPER_ADMIN: "Super Admin",
  ADMIN_ROLE: "Administrador",
  USER_ROLE: "Usuario",
};

export const roleValues = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN_ROLE: "ADMIN_ROLE",
  USER_ROLE: "USER_ROLE",
}