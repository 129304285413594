import * as yup from 'yup';

const MAX_EXCEL_FILE_SIZE = 1000000;
const MAX_ZIP_FILE_SIZE = 20000000;

export const excelCourseSchema = yup.object({
    excelFile: yup.mixed()
        .test("fileSize", "El archivo es muy grande (mayor que 2 MB)", (value) => {
            if (value) return value.size <= MAX_EXCEL_FILE_SIZE;
            return true;
        }).required('El archivo EXCEL es obligatorio'),
    zipFile: yup.mixed()
        .test("fileSize", "El archivo es muy grande (mayor que 2 MB)", (value) => {
            if (value) return value.size <= MAX_ZIP_FILE_SIZE;
            return true;
        }).notRequired(),
});