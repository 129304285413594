import AdminRoutes from "./AdminRoutes";
import PublicRoutes from "./PublicRoutes";

export const AppRouter = () => {
  const token = localStorage.getItem('token');

  const isLogged = Boolean(token) && token !== "";

  const AppRoutes = isLogged ? AdminRoutes : PublicRoutes;

  return <AppRoutes />;
};