
export const stepsAddCourseByFileData = [
    {
      title: <span className="fw-bold">Título (obligatorio)</span>,
      content: <span>Título del programa que describirá al curso. Será visible para todos.</span>,
    },
    {
      title: <span className="fw-bold">Facultad (obligatoria)</span>,
      content: <span>
        Selecciona la facultad por defecto y no se puede modificar. Por ejemplo, si entramos en la facultad 
        de medicina, la facultad seleccionada por defecto será la facultad de medicina, y no se podra cambiar.
      </span>,
    },
    {
      title: <span className="fw-bold">Registro Institucional (obligatorio)</span>,
      content: <span>
        Deberá ser único y correspondiente a la facultad seleccionada.
      </span>,
    },
    {
      title: <span className="fw-bold">Fecha de inicio y Fecha de terminación (obligatorias)</span>,
      content: <span>
        Las fechas deberán tener coherencia. Por ejemplo, un curso no puede iniciar el 11/11/2022 y terminar
        el 07/06/2022.
      </span>,
    },
    {
      title: <span className="fw-bold">Contacto (obligatorio)</span>,
      content: <span>
        Es el nombre del responsable del curso.
      </span>,
    },
    {
      title: <span className="fw-bold">Teléfono (obligatorio)</span>,
      content: <span>
        Es el número de teléfono del responsable del curso. Debe ser un número telefónico real, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },
    {
      title: <span className="fw-bold">Extensión</span>,
      content: <span>
        Si el número de teléfono requiere una extensión para localizarlo, entonces se debe ingresar.
      </span>,
    },
    {
      title: <span className="fw-bold">Correo electrónico (obligatorio)</span>,
      content: <span>
        Es el correo electrónico del responsable del curso. Debe ser un correo vigente, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },

    {
      title: <span className="fw-bold">Costo del curso</span>,
      content: <span>
        Deberá ser una cantidad positiva, puede tener un valor numérico o simplemente escribir el costo en cero (0)
        cuando el curso sea gratis.
      </span>,
    },

    {
      title: <span className="fw-bold">Imagen del curso</span>,
      content: <span>
        Una imagen para el curso puede hacerlo más llamativo. La imagen para el curso no es requerida. Si no
        se elige una imagen entonces el sistema le asignará una por defecto.
      </span>,
    },


    {
      title: <span className="fw-bold">Casilla "Publicado"</span>,
      content: <span>
        Si marca la casilla de <span className="fw-bold">Publicado</span>, el curso se mostrará
        en la pagina de principal y será visible para todos los usuarios. Si no selecciona la casilla, 
        el curso estará agregado en el sistema, pero no será visible para los usuarios. 
      </span>,
    },
  ];
  
  