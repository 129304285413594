import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { FormFileInput } from "../components/form/FormFileInput"
import { BodyContainer } from "../components/general/BodyContainer"
import { PageParagraph } from "../components/general/PageParagraph"
import { PageTitle } from "../components/general/PageTitle"
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import useAlert from "../hooks/useAlert"
import useAxios from "../hooks/useAxios"
import { useFacultyInfo } from "../hooks/useFacultyInfo"
import { useForm } from "../hooks/useForm"
import { useLoading } from "../hooks/useLoading"
import { excelCourseSchema } from "../schemas/excelCourseSchema"
import { useSocket } from "../hooks/useSocket"
import { ProgressBar } from "../components/general/ProgressBar"
import { CodeTextArea } from "../components/general/CodeTextArea"
import { LargeButton } from "../components/general/LargeButton"
import 'animate.css';
import { useRedirectIfForbidden } from "../hooks/useRedirectIfForbidden"
import useMyInfo from "../hooks/useMyInfo"
import { roleValues } from "../data/role"
import { useCoursesCRUD } from "../hooks/courses/useCoursesCRUD"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const templateFileURL = process.env.REACT_APP_EXCEL_TEMPLATE;

export const AddCourseByFilePage = () => {

    const { facID } = useParams();
    const { faculty } = useFacultyInfo({ id: facID });
    const navigate = useNavigate();
    const { post } = useAxios();
    const { openAlert } = useAlert();
    const { setLoading } = useLoading();
    const { socket, on, off, emit } = useSocket();
    const user = useMyInfo();
    const { addCourseByFile } = useCoursesCRUD(true);

    const { redirectIfForbidden } = useRedirectIfForbidden();

    const facPart = facID ? "/" + facID : '';

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Cursos", path: "/courses" },
            ...(!!facID ? [
                { name: faculty.name, path: `/courses/${facID}` }
            ] : []),
            { name: "Opciones", path: `/add/course${facPart}/options` },
            { name: "Agregar curso", path: `/add/course${facPart}/by-file` },
        ];
    }, [faculty, facID]);

    const {
        excelFile,
        zipFile,
        onInputChange,
        errors,
        formState,
        validateFormState,
        hasBeenSubmited,
    } = useForm({
        excelFile: undefined,
        zipFile: undefined,
    }, excelCourseSchema);

    const [messageLog, setMessageLog] = useState("");
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [uploadError, setUploadError] = useState(false);

    const updateMessageLog = (args) => {
        let { status, msg } = args;
        const entry = `[${status ? '✔️' : (status === undefined ? '🟢' : '⚠️')}] ${msg}`;
        setMessageLog(prev => {
            return prev + entry + '\n';
        });
    }

    const updateProgress = (args) => {
        const { from, to } = args;
        setProgress((from / to) * 100);
    }

    useEffect(() => {
        on('enviar-mensaje', updateMessageLog);
        return () => {
            off('enviar-mensaje', updateMessageLog);
        }
    }, []);

    useEffect(() => {
        on('progress', updateProgress);
        return () => {
            off('progress', updateProgress);
        }
    }, []);

    useEffect(() => {
        checkIfForbidden();
    }, []);

    const checkIfForbidden = async () => {
        if (user.isUserLoading) await user.getMyInfo();
        if (user.rol === roleValues.USER_ROLE && user.facultad_id !== facID) {
            redirectIfForbidden('/add/course/options', true);
        }
    }

    const onReturn = () => navigate(-1);

    const onFileChange = (event) => {
        const file = event.target.files[0];
        onInputChange({
            target: {
                name: event.target.name,
                value: file,
            }
        });
    }

    const onConfirm = async () => {
        return await openAlert(
            "Subir archivos",
            <>
                Una vez iniciado el proceso, <b>no se
                    podrá cancelar</b>. ¿Desea continuar?
            </>,
            undefined, false, "info", undefined, undefined, 4000
        );
    }

    const onFinished = async () => {
        return await openAlert(
            "Archivos subidos",
            <>
                Se ha completado la subida de los archivos.
            </>,
            "success", false, undefined, undefined, 4000,
        );
    }

    const onFailed = async (error) => {
        return await openAlert(
            "Error en la subida de archivos",
            <>
                No se ha podido completar la operación.&nbsp;
                {error
                    ? <b>{error}</b>
                    : "Inténtelo de nuevo más tarde"}.
            </>,
            "error", false, null, null, 4000,
        );
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        hasBeenSubmited();
        const isValid = validateFormState();
        if (!isValid) return;

        const accepted = await onConfirm();
        if (!accepted) return;

        const data = { socket, ...formState };
        setIsUploading(true);
        addCourseByFile(data).then(({ ok, response, error }) => {
            setIsFinished(true);
            if (ok) {
                return onFinished();
            }

            setUploadError(true);
            return onFailed(error);
        });
    }

    return (
        <PageWithSidebar links={links}>

            <form className="needs-validation" noValidate onSubmit={onSubmit}>

                <PageTitle>Agregar curso</PageTitle>

                <PageParagraph>
                    Para poder subir cursos por medio de un archivo EXCEL, primero es necesario
                    descargar la <a href={templateFileURL}><b>plantilla</b></a>, llenarla con los
                    campos correspondientes, y luego subirla en esta página.
                </PageParagraph>

                <a href={templateFileURL}>
                    <LargeButton
                        value={<><FontAwesomeIcon icon={faFileDownload} />&nbsp;Descargar plantilla</>}
                        hideTextWhenBreakpoint
                        className="my-3"
                        classType="primary"
                    />
                </a>

                <PageParagraph>
                    Seleccione un archivo con <b>formato EXCEL</b>.
                </PageParagraph>

                <BodyContainer>
                    <FormFileInput
                        type="file"
                        name="excelFile"
                        required
                        accept=".xlsx, .xls, .csv"
                        onChange={onFileChange}
                        disabled={isUploading}
                        invalidFeedback={errors.excelFile}
                    />
                </BodyContainer>

                <PageParagraph>
                    Seleccione un archivo con <b>formato ZIP</b> con las imágenes del curso.
                </PageParagraph>

                <BodyContainer>
                    <FormFileInput
                        type="file"
                        name="zipFile"
                        required
                        accept=".zip, .rar"
                        onChange={onFileChange}
                        disabled={isUploading}
                        invalidFeedback={errors.zipFile}
                    />
                </BodyContainer>

                {
                    isUploading && !uploadError &&
                    <div className="animate__animated animate__fadeIn">
                        {
                            !isFinished
                                ? <PageParagraph>Se están subiendo los archivos...</PageParagraph>
                                : <PageParagraph>Se han subido los archivos.</PageParagraph>
                        }
                        <ProgressBar progress={progress} finished={isFinished} />
                        <CodeTextArea
                            disabled={true}
                            value={messageLog}
                            rows={messageLog.split(/\r\n|\r|\n/).length || 1}
                        />
                    </div>
                }

                {
                    uploadError &&
                    <PageParagraph>
                        <span className="text-danger">
                            Hubo un error al subir los archivos. Reinicie la página para
                            volver a intentarlo.
                        </span>
                    </PageParagraph>
                }

                {
                    !isUploading &&
                    <ActionFormButtonGroup>
                        <ActionFormButton classType='secondary' type="button" value="Cancelar" onClick={onReturn} />
                        <ActionFormButton classType='info' type="submit" value="Aceptar" />
                    </ActionFormButtonGroup>
                }

                {
                    isFinished &&
                    <div className="animate__animated animate__fadeIn">
                        <LargeButton onClick={onReturn} value="Regresar" classType="info" />
                    </div>
                }

            </form>

        </PageWithSidebar>
    )
}
