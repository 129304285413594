import addUser1 from '../../../assets/img/addUser/addUser1.png'
import addUser2_1 from '../../../assets/img/addUser/addUser2.1.png'
import addUser3 from '../../../assets/img/addUser/addUser3.png'
import addUserError from '../../../assets/img/addUser/addUserError.png'


import { PageImage } from "../../../components/general/PageImage";
import { Accordion } from '../../../components/general/Accordion'

import { ListContent } from '../../../components/general/ListContent'

import { 
  stepsAddUserData1,
  stepsAddUserData2_2 
} from '../info/stepsList/stepsAddUserData'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'


export const stepsAddUser = [
    
    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 1: </span> 
        Diríjase a la página de usuarios
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            En la barra lateral izquierda, diríjase a la sección de
            <span className="fw-bold"> <FontAwesomeIcon icon={faUser} className={"fa-md"}/> Usuarios</span>
            , en la que se mostrará la siguiente imagen. Podrá observar que existe el botón 
            <span className="fw-bold"> + Agregar usuario</span>. Debajo se muestra una barra de búsqueda con todas
            las facultades. Para agregar un nuevo usuario puede presionar el botón de 
            <span className="fw-bold"> + Agregar usuario</span> o seleccionar alguna facultad de la lista.
          </p>
          <PageImage
            src={addUser1}
            title={""}
            alt={""}
          />
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        En caso de presionar el botón
        <span className='fw-bold'> + Agregar usuario </span>
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Al presionar el botón <span className='fw-bold'> + Agregar usuario </span>
            se mostrará el formulario de la siguiente imagen. Para agregar un nuevo usuario con el formulario
            necesita:
          </p>
          <ListContent list={stepsAddUserData1}/>
          <PageImage
            src={addUser2_1}
            title={""}
            alt={""}
          />
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        En caso de seleccionar una facultad
      </span>,
      content: 
        <div className="px-3 py-3">
          <Accordion questions={stepsAddUserData2_2}/>
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 3: </span>
          Presione el botón aceptar
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Si el formulario se llenó correctamente, se mostrará el siguiente mensaje.
            El usuario o administrador habrá quedado registrado en el sistema.
          </p>
            
            <p className="fw-bold pt-2">
              Nota: El usuario o administrador deberá revisar su correo con el que fue registrado. 
              Observará que tiene un correo con su contraseña para acceder al sistema.
            </p>
          <PageImage
            src={addUser3}
            title={""}
            alt={""}
          />
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Mensajes de errores </span>
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Cuando intente agregar un usuario o administrador y reciba un mensaje de error,
            puede deberse a diferentes motivos (conexión a internet o un campo mal llenado). 
            Visite la sección de <span className="fw-bold">Preguntas frecuentes</span> para 
            obtener más información.
          </p>
          <PageImage
            src={addUserError}
            title={""}
            alt={""}
          />
        </div>
    },


  ]