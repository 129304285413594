  import { PageImage } from "../../../components/general/PageImage";
  import { Accordion } from '../../../components/general/Accordion'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faMortarBoard } from '@fortawesome/free-solid-svg-icons'
  
  import addCourseByFile1 from '../../../assets/img/addCourse/addCourse1.png'
  import addCourseByFile3 from '../../../assets/img/addCourseByFile/addCourseByFile1.2.3.4.5.png'
  import { 
    stepsAddCourseByFileData2_1_1,
    stepsAddCourseByFileData2_1_2 
  } from '../info/stepsList/stepsAddCourseByFileData'


  export const stepsAddCourseByFile = [
  

    
    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 1: </span> 
        Diríjase a la página de cursos
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            En la barra lateral izquierda, diríjase a la sección de
            <span className="fw-bold"> <FontAwesomeIcon icon={faMortarBoard} className={"fa-md"}/> Cursos </span>
            que se muestra en la siguiente imagen. Podrá observar que existe el botón 
            <span className="fw-bold"> + Agregar curso</span>. Debajo se muestra una barra de búsqueda con todas
            las facultades. Para agregar un nuevo curso puede presionar el botón de 
            <span className="fw-bold"> + Agregar curso</span> o seleccionar alguna facultad de la lista.
          </p>
          <PageImage
            src={addCourseByFile1}
            title={""}
            alt={""}
          />
        </div>
    },



    

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        En caso de presionar el botón
        <span className='fw-bold'> + Agregar curso </span>
      </span>,
      content: 
        <div className="px-3 pt-3">
          <Accordion questions={stepsAddCourseByFileData2_1_1}/>
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        En caso de seleccionar una facultad de la lista
      </span>,
      content: 
        <div className="px-3 pt-3">
          <Accordion questions={stepsAddCourseByFileData2_1_2}/>
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 3: </span>
          Presione el botón aceptar
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Al presionar el botón de aceptar, el sistema ingresará los cursos. 
            Se mostrarán mensajes de errores o advertencias si ocurre algún imprevisto con los cursos.
            En caso contrario, mostrará un mensaje de que los cursos fueron agregados correctamente.
          </p>
            
            <p className="fw-bold">
              Nota: Los cursos que en el archivo Excel tengan "true" en el campo "publicado",
              serán visibles para todos en la página principal, de lo contrario el curso solo 
              será visible para los administradores.
            </p>
            
            <p>
              <b>Nota 2: Si el rol del usuario actual es "Usuario", solamente podrá subir cursos que pertenezcan a
              la facultad asignada. Esto significa que si selecciona otra facultad, será ignorada por el sistema.</b>
            </p>
          <PageImage
            src={addCourseByFile3}
            title={""}
            alt={""}
          />
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Mensajes de errores </span>
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Cuando intente agregar un curso y reciba un mensaje de error,
            esto puede deberse a diferentes motivos (el archivo Excel contiene campos incorrectos,
            faltan campos por llenar, entre otros).
            Visite la sección de <span className="fw-bold">Preguntas frecuentes</span> para 
            obtener más información.
          </p>
        </div>
    },

  ]