import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/LoginPage";
import { RestorePasswordPage } from "../pages/RestorePasswordPage";
import { ForgotPasswordPage } from "../pages/ForgotPasswordPage";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/restore-password/:token" exact element={<RestorePasswordPage />} />
      <Route path="/forgot-password" exact element={<ForgotPasswordPage />} />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default PublicRoutes;
