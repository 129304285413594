
export const FormTextAreaInput = ({
    label,
    name,
    value,
    type,
    rows,
    resizable,
    placeholder,
    required = false,
    onChange,
    validFeedback,
    invalidFeedback,
}) => {
    
    const display = invalidFeedback?.length > 0 ? 'd-block' : 'd-none';

    return (
        <div className="py-2">
            {label && <label htmlFor={name} className="form-label">
                {label}{required && <span className="text-danger">&nbsp;*</span>}
            </label>}
            <textarea
                id={name}
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                required={required}
                className={`form-control`}
                style={{ resize: resizable ? 'vertical' : 'none' }}
                rows={rows}
            />
                <div className="valid-feedback">
                    {validFeedback}
                </div>
                <div className={`invalid-feedback ${display}`}>
                    {invalidFeedback}
                </div>
        </div>
    )
}
