export const RowDetail = ({
    header, 
    fields = [],
    colSpan
}) => {

    return (
        
        <tr>
            <th scope="row" className="title-row">{header}</th>
            {fields.map((field, index) =>
                <td key={`${field}-${index}-key`} colSpan={colSpan}>{field}</td>
            )}
        </tr>
        
    );
}
