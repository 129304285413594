import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { roleValues } from "../data/role";
import useAlert from './useAlert';
import useMyInfo from "./useMyInfo";

// En cuando se use, corre el useEffect y verifica/redirecciona si está prohibido
export const useRedirectIfForbidden = () => {

    const { openAlert } = useAlert();
    const navigate = useNavigate();

    const redirectIfForbidden = async (to = '/', replace = true) => {
        openAlert(
            "Error al cargar la página",
            <>
                La página a la que accedió no está permitida.
            </>,
            undefined, true, "secondary", undefined, undefined,
        );
        navigate(to, { replace });
    }

    return {
        redirectIfForbidden,
    };
}