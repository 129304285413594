import { useContext, useEffect } from "react"
import { FacultiesContext } from "../../providers/FacultiesProvider"
import Breadcrumb from "../BreadCrumb"
import { AdminSidebar } from "../SidebarPageX"
import Stickybutton from "../StickyButton"

export const PageWithSidebar = ({ links, children }) => {

    const { loadFaculties } = useContext(FacultiesContext);

    return (
        <AdminSidebar>
            <Breadcrumb links={links} />
            <div className="cont">
                {children}
            </div>
        </AdminSidebar>
    )
}
