import { useEffect, useMemo, useState } from "react"
import _ from "lodash";
import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { FormImageSelectInput } from "../components/form/FormImageSelectInput"
import { FormSelectInput } from "../components/form/FormSelectInput"
import { FormTextInput } from "../components/form/FormTextInput"
import { BodyContainer } from "../components/general/BodyContainer"
import { LargeButton } from "../components/general/LargeButton"
import { Link } from "react-router-dom";
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import { PageParagraph } from "../components/general/PageParagraph"
import { PageTitle } from "../components/general/PageTitle"
import { useForm } from "../hooks/useForm"

import { useNavigate, useParams } from "react-router-dom"
import { useFacultyInfo } from "../hooks/useFacultyInfo"
import { useLoading } from "../hooks/useLoading"
import useAxios from "../hooks/useAxios"
import useAlert from "../hooks/useAlert";
import { obtainRole } from "../functions";
import useMyInfo from "../hooks/useMyInfo";
import { roleValues } from "../data/role";
import { userSchema } from "../schemas/userSchema";
import { useUsersCRUD } from "../hooks/users/useUsersCRUD";
import { useRedirectIfForbidden } from "../hooks/useRedirectIfForbidden";
import { ExternalFacData } from "../data/categories/ExternalFacData";
import imgUser from "../assets/img/default-user-image.png";

const externalFacID = ExternalFacData.id;

const initialValues = {
    name: '',
    email: '',
    role: '',
    faculty: '',
    password: '',
    image: null,
};

const getSelectvalues = (userRole) => {
    switch (userRole) {
        case "SUPER_ADMIN":
            return [
                { value: 'none', option: 'Seleccione una opción', },
                { value: 'SUPER_ADMIN', option: 'Super Administrador', },
                { value: 'ADMIN_ROLE', option: 'Administrador', },
                { value: 'USER_ROLE', option: 'Usuario', },
            ];
        case "ADMIN_ROLE":
            return [
                { value: 'none', option: 'Seleccione una opción', },
                { value: 'ADMIN_ROLE', option: 'Administrador', },
                { value: 'USER_ROLE', option: 'Usuario', },
            ];
        default: // USER_ROLE
            return [
                { value: 'none', option: 'Seleccione una opción', },
                { value: 'USER_ROLE', option: 'Usuario', },
            ];
    }
}

export const AddUserPage = () => {

    const { facID, userID } = useParams();
    const { faculty: facObject } = useFacultyInfo({ id: facID });
    const { setLoading } = useLoading();
    const { get } = useAxios();
    const navigate = useNavigate();

    const { openAlert } = useAlert();
    const { redirectIfForbidden } = useRedirectIfForbidden();
    const { getUser, getMyUser, addUser, editUser, deleteUserImage } = useUsersCRUD(true);

    const userData = useMyInfo();

    const isEdit = !!userID;
    const haveFaculty = !!facID;

    const facPart = haveFaculty ? "/" + facID : '';

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Usuarios y administradores", path: "/users" },
            ...(haveFaculty ? [{ name: facObject.name, path: `/users${facPart}` }] : []),
            ...(isEdit ? [
                { name: "Editar usuario", path: `/edit/user${facPart}/form/${userID}` },
            ] : []),
            ...(!isEdit ? [
                { name: "Agregar usuario", path: `/add/user${facPart}` },
            ] : []),
        ];
    }, [facID, facObject]);

    const {
        name,
        email,
        role,
        faculty,
        password,
        image,
        onInputChange,
        formState,
        setFormState,
        errors,
        validateFormState,
        hasBeenSubmited,
    } = useForm(
        haveFaculty
            ? { ...initialValues, faculty: facID }
            : initialValues,
        userSchema,
    );

    const [imageURL, setImageURL] = useState('');
    const [imageInfo, setImageInfo] = useState({
        hadImageBefore: false,
        changedImage: false,
        deletedImage: false,
    });
    const [faculties, setFaculties] = useState([]);

    const isUserRole = userData.rol === roleValues.USER_ROLE;

    const values = faculty !== externalFacID
        ? getSelectvalues(userData.rol)
        : getSelectvalues(userData.rol).filter(options => options.value !== roleValues.USER_ROLE);

    useEffect(() => {
        loadFaculties();
        if (isEdit) loadUser();
    }, []);

    useEffect(() => {
        if (!isEdit) checkIfForbidden();
    }, []);

    const checkIfForbidden = async () => {
        if (userData.isUserLoading) await userData.getMyInfo();
        if (userData.rol === roleValues.USER_ROLE) {
            redirectIfForbidden('/', true);
        }
    }

    const loadUser = async () => {
        let result;
        if (userData.id === userID) {
            result = await getMyUser();
        } else {
            result = await getUser(userID);
        }
        const { ok, data, response, error } = result;
        if (ok) {
            const user = data.user;
            //// console.log(user);
            setFormState(prev => ({
                ...prev, ...user,
                img: undefined,
            }));
            setImageURL(user.img || '');
            setImageInfo({
                ...imageInfo,
                hadImageBefore: !!user.img,
            })
            return;
        } else {
            if (response.status === 403) {
                redirectIfForbidden('/', true);
            } else {
                openAlert(
                    `Error al cargar el usuario`,
                    <>
                        No se ha podido completar la operación.&nbsp;
                        {error
                            ? <b>{error}</b>
                            : "Inténtelo de nuevo más tarde"}.
                    </>,
                    "error", false, null, null, 4000,
                );
                navigate(-1);
            }
        }
    }

    const loadFaculties = async () => {
        try {
            setLoading(true);
            const resp = await get(`public/facultades`);
            if (resp.status === 200) {
                const facs = resp.data.items;
                const mapped = facs.map(i => ({
                    value: i._id,
                    option: i.facultad,
                }));
                setFaculties([{
                    value: 'none',
                    option: 'Seleccione una opción'
                }, ...mapped]);
                return;
            }
            // console.log(resp);
        } catch (error) {
            const message = _.get(error, "response.data.errors[0].msg");
            openAlert(
                "Error al cargar facultades",
                <>
                    Ocurrió un error al cargar la lista de facultades.&nbsp;
                    {message
                        ? <b>{message}</b>
                        : "Inténtelo de nuevo más tarde"}.
                </>,
                "error", undefined, undefined, undefined, 4000,
            );
            navigate(-1);
        } finally {
            setLoading(false);
        }
    }

    const onChangeImage = (event) => {
        const img = event.target.files[0];
        if (!!img) {
            onInputChange({ target: { name: 'image', value: img } });

            const url = !!img ? URL.createObjectURL(img) : '';
            setImageURL(url);

            // Esto es para borrar la imagen pasada cuando ya se cambió
            setImageInfo({
                ...imageInfo,
                changedImage: true,
            });
        }
    }

    const onRemoveImage = () => {
        onInputChange({ target: { name: 'image', value: null } });
        setImageURL('');
        setImageInfo({
            ...imageInfo,
            deletedImage: true,
        });
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        //// console.log({ ...formState, image });

        hasBeenSubmited();
        const isValid = validateFormState();
        if (!isValid) {
            openAlert(
                "Errores en los campos",
                <>
                    Por favor revise los campos del formulario
                    e inténtelo nuevamente.
                </>,
                "error", false, null, null, 4000,
            );
            return;
        }

        //* Checar si se necesita borrar imagen pasada
        let delImgResult = true;
        if ((imageInfo.deletedImage || imageInfo.changedImage) && imageInfo.hadImageBefore) {
            //* Borrando imagen que tiene actualmente (antes de subir los cambios de edición)
            // (esto lo hice para borrar las imagenes pasadas antes de quitar/subir una nueva)
            const { ok } = await deleteUserImage(userID);
            delImgResult = ok;
        }

        //* Agregar / Editar usuario
        const data = {
            ...formState,
        };

        let result;
        if (!isEdit) {
            result = await addUser(data);
        } else {
            result = await editUser(data, userID);
        }

        if (result.ok) {
            openAlert(
                `${obtainRole(role)} ${isEdit ? 'editado' : 'agregado'}`,
                <>
                    El {obtainRole(role)} "<b>{name}</b>" ha
                    sido {isEdit ? 'editado' : 'agregado'} correctamente.&nbsp;
                    {!delImgResult && <>Sin embargo, no se pudo borrar la imagen.</>}
                </>,
                delImgResult ? "success" : "warning", false, null, null, 4000,
            )
            if (isEdit && userData.id === userID) await userData.getMyInfo();
            navigate(-1, { replace: true });
        } else {
            openAlert(
                `Error al ${isEdit ? 'editar' : 'agregar'} el ${obtainRole(data.role)}`,
                <>
                    No se ha podido completar la operación.&nbsp;
                    {result.error
                        ? <b>{result.error}</b>
                        : "Inténtelo de nuevo más tarde"}.
                </>,
                "error", false, null, null, 4000,
            )
        }
    }

    return (
        <PageWithSidebar links={links}>

            <form className="needs-validation" noValidate onSubmit={onSubmit}>

                <PageTitle>{isEdit ? "Editar" : "Agregar"} usuario</PageTitle>
                <PageParagraph>
                    Llene el siguiente formulario.
                </PageParagraph>

                <BodyContainer>

                    <div className="row g-3">

                        <div className="col-md-4">
                            <FormImageSelectInput
                                label="Imagen de perfil"
                                name="profile-pic"
                                value={image}
                                src={imageURL}
                                altText="Previsualización de imagen de perfil"
                                defaultImage={imgUser}
                                onChange={onChangeImage}
                                onClickRemove={onRemoveImage}
                                invalidFeedback={errors.image}
                            />
                        </div>

                        <div className="col-md-8">
                            <FormTextInput
                                label="Nombre"
                                name="name"
                                value={name}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Nombre de la persona'
                                invalidFeedback={errors.name}
                            />

                            <FormTextInput
                                label="Correo electrónico"
                                name="email"
                                value={email}
                                type="email"
                                required
                                onChange={onInputChange}
                                placeholder='Correo electrónico de la persona'
                                invalidFeedback={errors.email}
                            />

                            <FormSelectInput
                                label="Rol"
                                name="role"
                                disabled={isUserRole}
                                value={role}
                                options={values}
                                required
                                onChange={onInputChange}
                                invalidFeedback={errors.role}
                            />

                            <FormSelectInput
                                label="Facultad"
                                disabled={haveFaculty}
                                name="faculty"
                                value={faculty}
                                options={faculties}
                                required
                                onChange={onInputChange}
                                invalidFeedback={errors.faculty}
                            />
                        </div>

                    </div>

                </BodyContainer>

                {
                    isEdit && userData.id === userID ?
                        <div>
                            <PageParagraph>
                                En caso de querer cambiar la contraseña, haga click en el siguiente botón.
                            </PageParagraph>

                            <BodyContainer>
                                <Link to={`/user/restore`}>
                                    <LargeButton value="Reestablecer contraseña" marginBottom={false} />
                                </Link>
                            </BodyContainer>
                        </div>
                        :
                        <div>
                        </div>
                }

                {
                    isEdit ?
                        <div>
                            <PageParagraph>
                                Para confirmar la operación, ingrese su <b>contraseña actual</b> en el siguiente campo.
                            </PageParagraph>

                            <BodyContainer>
                                <FormTextInput
                                    label="Contraseña"
                                    name="password"
                                    type="password"
                                    required
                                    onChange={onInputChange}
                                    placeholder='*************'
                                    invalidFeedback={errors.password}
                                />
                            </BodyContainer>
                        </div>
                        :
                        <div>
                        </div>
                }


                <ActionFormButtonGroup>
                    <ActionFormButton
                        classType='secondary'
                        type="button"
                        value="Cancelar"
                        onClick={() => navigate(-1)}
                    />
                    <ActionFormButton classType='info' type="submit" value="Aceptar" />
                </ActionFormButtonGroup>

            </form>

        </PageWithSidebar>
    )
}