import * as yup from 'yup';

export const restorePassSchema = yup.object({
    password: yup.string()
        .min(8,"La contraseña debe tener al menos 8 caracteres")
        .max(12, "La contraseña debe tener a lo más 12 caracteres")
        .required('La contraseña es obligatoria'),
    repeatPassword: yup.string()
        .oneOf([yup.ref('password')], 'Las contraseñas no coinciden')
        .min(8,"La contraseña debe tener al menos 8 caracteres")
        .max(12, "La contraseña debe tener a lo más 12 caracteres")
        .required('Por favor repita la contraseña'),
});