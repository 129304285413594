
export const ActionButton = ({ value = 'Botón', onClick = () => { }, type = 'info', className = '', colXXL = 12, disabled = false }) => {

    return (
        <div className="style-action-button-div">
            <button
                disabled={disabled}
                type="button"
                className={`btn btn-${type} text-button text-action-button border-0 ${className} style-action-button`}
                onClick={onClick}
            >
                {value}
            </button>
        </div>
    );
}
