import { useEffect, useMemo, useState } from "react"
import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { FormImageSelectInput } from "../components/form/FormImageSelectInput"
import { FormSelectInput } from "../components/form/FormSelectInput"
import { FormTextInput } from "../components/form/FormTextInput"
import { BodyContainer } from "../components/general/BodyContainer"
import { LargeButton } from "../components/general/LargeButton"
import { Link } from "react-router-dom";
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import { PageParagraph } from "../components/general/PageParagraph"
import { PageTitle } from "../components/general/PageTitle"
import { useForm } from "../hooks/useForm"
import _ from "lodash";

import { useNavigate, useParams } from "react-router-dom"
import { useFacultyInfo } from "../hooks/useFacultyInfo"
import { useLoading } from "../hooks/useLoading"
import useAxios from "../hooks/useAxios"
import useAlert from "../hooks/useAlert";
import { obtainRole } from "../functions";
import useMyInfo from "../hooks/useMyInfo";
import { useTokens } from "../hooks/useTokens";

import { restorePassSchema } from "../schemas/restorePassSchema";
import { useUsersCRUD } from "../hooks/users/useUsersCRUD"

const initialValues = {
  password: '',
  repeatPassword: '',
  oldPassword: '',
};

const links = [
  { name: "Inicio", path: "/" },
  { name: "Usuarios y administradores", path: "/users" },
  { name: "Modificar contraseña", path: `/user/restore` },
];

export const ModifyPasswordPage = () => {

  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const { freeTokens } = useTokens();
  const { restoreUserPassword } = useUsersCRUD(true);

  const {
    password,
    repeatPassword,
    oldPassword,
    onInputChange,
    errors,
    formState,
    validateFormState,
    hasBeenSubmited,
  } = useForm(
    {
      password: "",
      repeatPassword: "",
    },
    restorePassSchema,
  );

  const logout = () => {
    freeTokens();
    window.location.reload(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    hasBeenSubmited();
    const isValid = validateFormState();
    if (!isValid) {
      openAlert(
        "Errores en los campos",
        <>
          Por favor revise los campos del formulario
          e inténtelo nuevamente.
        </>,
        "error", false, null, null, 4000,
      );
      return;
    }

    const data = { ...formState };
    const { ok, response, error } = await restoreUserPassword(data);
    if (ok) {
      await openAlert(
        "Contraseña reestablecida",
        <>
          <p>
            Se ha cambiado la contraseña correctamente.
            Se debe volver a iniciar sesión ingresando la nueva contraseña.
          </p>
          <p>
            La sesión actual terminara automaticamente
            en <i>30 segundos</i>.
          </p>
        </>,
        undefined, true, "info", undefined, 30000,
      );
      navigate('/');
      // console.log('Cerrar sesión');
      logout();
    } else {
      openAlert(
        "Error al cambiar la contraseña",
        <>
          Ocurrió un error al modificar la contraseña.&nbsp;
          {error
            ? <b>{error}</b>
            : "Inténtelo de nuevo más tarde"}.
        </>,
        "error", false, null, null, 4000,
      );
    }
  };


  return (
    <PageWithSidebar links={links}>


      <PageTitle>Modificar contraseña</PageTitle>
      <PageParagraph>
        Llene el siguiente formulario.
      </PageParagraph>

      <form onSubmit={handleSubmit} className="needs-validation" noValidate>

        <BodyContainer>

          <FormTextInput
            label="Nueva contraseña"
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={onInputChange}
            placeholder="************"
            invalidFeedback={errors.password}
            required
          />

          <FormTextInput
            label="Repita la nueva contraseña"
            id="repeatPassword"
            type="password"
            name="repeatPassword"
            value={repeatPassword}
            onChange={onInputChange}
            placeholder="************"
            invalidFeedback={errors.repeatPassword}
            required
          />

        </BodyContainer>

        <PageParagraph>
          Para confirmar la operación, ingrese <span className="fw-bold">su contraseña actual </span>
          para modificar su contraseña.
        </PageParagraph>

        <BodyContainer>
          <FormTextInput
            label="Contraseña actual"
            id="oldPassword"
            type="password"
            name="oldPassword"
            onChange={onInputChange}
            placeholder="************"
            invalidFeedback={''}
            required
          />
        </BodyContainer>

        <ActionFormButtonGroup>
          <ActionFormButton
            classType='secondary'
            type="button"
            value="Cancelar"
            onClick={() => navigate(-1)}
          />
          <ActionFormButton classType='info' type="submit" value="Cambiar contraseña" />
        </ActionFormButtonGroup>


      </form>

    </PageWithSidebar>
  )
}