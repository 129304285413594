import React, { useState, createContext, useEffect, useMemo } from "react";
import { roleValues } from "../data/role";
import useAxios from "../hooks/useAxios";
import _ from 'lodash';
import useAlert from "../hooks/useAlert";

const defaultUser = {
  estado: false,
  nombre: "",
  correo: "",
  rol: "",
  facultad: "",
  facultad_id: undefined,
  img: "",
  id: "",
};

export const UserContext = createContext(defaultUser);

const UserProvider = (props) => {
  const { get } = useAxios();
  const [user, setUser] = useState(defaultUser);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const { openAlert } = useAlert();

  useEffect(() => {
    getMyInfo();
  }, []);

  const getMyInfo = async () => {
    try {
      setIsUserLoading(true);
      const response = await get("/auth/my_info");
      if (response.data && response.data.id) {
        setUser({ ...user, ...response.data });
      }
      return response;
    } catch (error) {
      const message = _.get(error, "response.data.errors[0].msg");
      openAlert(
        "Error al cargar información del usuario",
        <>
          Ocurrió un error al cargar la información del usuario.&nbsp;
          {message
            ? <b>{message}</b>
            : "Inténtelo de nuevo más tarde"}.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
      return error;
    } finally {
      setIsUserLoading(false);
    }
  };

  const value = useMemo(() => ({ ...user, getMyInfo, isUserLoading }), [user, isUserLoading]);
  // console.log(value);

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
