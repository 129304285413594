
export const ProgressBar = ({ progress = 0, min = 0, max = 100, finished = 'false' }) => {

    const stripped = finished ? "" : 'progress-bar-striped';

    return (
        <div className="progress mb-3" style={{height: '36px'}}>
            <div
                className={`progress-bar ${stripped} progress-bar-animated bg-info`}
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin={min}
                aria-valuemax={max}
                style={{width: progress+'%'}}
            >{progress}%</div>
        </div>
    );
}
