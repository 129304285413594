import React from "react";
import { Modal } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import "../../css/loading.css";

export const Loading = ({ loading = false }) => {
  const onClose = () => {};
  return (
    <Modal open={loading} onClose={onClose}>
      <div className="loading-modal-root">
        <ClipLoader size={50} color="#0dcaf0" />
      </div>
    </Modal>
  );
};
