import { Link } from "react-router-dom";
import logo from "../../assets/img/logo-buap.png";

export const PageWithCenteredSquare = ({ children }) => {
    return (
        <div className="bg-login">
            <div className="login-card-center ">
                <div className="login-card-center">
                    <div className="card login-background background-text border login-card">
                        <div className="card-body">
                            <Link to='/login'>
                                <div className="py-1 user-select-none">
                                    <div className="text-center">
                                        <img
                                            src={logo}
                                            id="imgLogo"
                                            className="img-fluid mb-3 mt-1 img-margin img-logo-size"
                                            alt="Escudo de la BUAP"
                                        />
                                    </div>
                                    <h3 className="text-center text-uni">Educación Continua</h3>
                                </div>
                            </Link>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
