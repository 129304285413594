import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import useMyInfo from "../hooks/useMyInfo";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNavicon, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import logo from "../assets/img/logo-buap-white.png";
import { SidebarData } from '../data/SidebarData';
import { useTokens } from "../hooks/useTokens";
import { roleValues } from "../data/role";
import { useSidebar } from "../hooks/useSidebar";

export const AdminSidebar = (props) => {

    const { showingSidebar, setShowingSidebar } = useSidebar();
    const { freeTokens } = useTokens();
    const navigate = useNavigate();
    const user = useMyInfo();

    const role = user.rol?.length > 0 ? user.rol : roleValues.USER_ROLE;

    const logout = () => {
        freeTokens();
        window.location.reload(false);
    };

    return (
        <main className={showingSidebar ? 'space-toggle' : null}>

            <header className={`header ${showingSidebar ? 'space-toggle' : null}`}>
                <div
                    className="header-toggle"
                    onClick={() => setShowingSidebar(!showingSidebar)}>
                    <FontAwesomeIcon
                        icon={faNavicon}
                        className={"pe-2 fa-xl"}
                    />
                </div>
            </header>

            <aside className={`sidebar ${showingSidebar ? 'show-side' : null}`}>
                <nav className="nav">
                    <div>
                        <Link to="/" className="nav-logo">

                            <div className="row">
                                <div className="col-12">
                                    <img src={logo}
                                        className={showingSidebar ? 'img-max' : 'img-min'}
                                        alt="imagen logo" />
                                </div>
                                <div className="col-12">
                                    <span className={`nav-logo-name ${showingSidebar ? 'show-title' : 'hide-title'}`}>
                                        Educación Continua
                                    </span>
                                </div>
                            </div>
                        </Link>
                        <div className="nav-list">
                            {SidebarData
                                .filter((item) => role !== roleValues.USER_ROLE || item.visibleToUser)
                                .map((item) => {
                                    return (
                                        <NavLink key={item.name} to={item.path}
                                            className={({ isActive }) => (`nav-link ${isActive ? 'active' : ''}`)}>
                                            <div className="nav-logo-icon">
                                                {item.icon}
                                            </div>
                                            <span className={`nav-link-name ${showingSidebar ? 'show-title' : 'hide-title'}`}>
                                                {item.title}
                                            </span>
                                        </NavLink>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <button onClick={logout} className="nav-footer">

                        <div className="nav-logo-icon">
                            <FontAwesomeIcon
                                icon={faSignOutAlt}
                                className={"fa-xl nav-link-icon"}
                            />
                        </div>
                        <span className={`nav-link-name ${showingSidebar ? 'show-title' : 'hide-title'}`}>
                            Cerrar sesión
                        </span>
                    </button>
                </nav>
            </aside>

            {props.children}

        </main>
    );
};
