import { PageImage } from "../../../components/general/PageImage";

import { ListContent } from '../../../components/general/ListContent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import addUser1 from '../../../assets/img/addUser/addUser1.png'
import modifyUser2 from '../../../assets/img/modifyUser/modifyUser2.png'
import modifyUser3 from '../../../assets/img/modifyUser/modifyUser3.png'
import modifyUser4 from '../../../assets/img/modifyUser/modifyUser4.png'


import { stepsAddUserData2 } from '../info/stepsList/stepsModifyUserInfo'


export const stepsModifyInfoUser = [
    
    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 1: </span> 
        Diríjase a la página de usuarios
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            En la barra lateral izquierda, diríjase a la sección de
            <span className="fw-bold"> <FontAwesomeIcon icon={faUser} className={"fa-md"}/> Usuarios</span>
            , en la que se mostrará la siguiente imagen. Debe identificar la facultad del usuario o administrador. 
            Una vez identifiado, seleccione la facultad.
          </p>
          <PageImage
            src={addUser1}
            title={""}
            alt={""}
          />
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        Diríjase a la facultad (lista de usuarios y administradores)
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Se desplegará una tabla con todos los usuarios y administradores que están activos e inactivos.
            Si el usuario o administrador se encuentra activo 
            <span className='fw-bold'> podrá editarlo (Editar)</span>, ver su información o 
            eliminarlo.
          </p>
          <PageImage
            src={modifyUser2}
            title={""}
            alt={""}
          />
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 3: </span>
        Edite el usuario o administrador
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Se desplegará una página con la información del usuario o administrador que podrá editar.
            La información que podrá editar es la siguiente:
          </p>

          <p className="fw-bold">
            Nota: La facultad no podrá modificarse.
          </p>

          <ListContent list={stepsAddUserData2}/>
          <PageImage
            src={modifyUser3}
            title={""}
            alt={""}
          />
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 4: </span>
          Presione el botón aceptar
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            La contraseña de un usuario y administrador solo puede ser modificada por él mismo.
            Para comfirmar la edición del usuario o administrador, debe escribir su contraseña.

            Si el formulario se editó, correctamente se mostrará un mensaje de confirmación.
          </p>
            
            <p className="fw-bold pt-2">
              Nota: Solo los administradores pueden editar información de otros usuarios y administradores.
            </p>
          <PageImage
            src={modifyUser4}
            title={""}
            alt={""}
          />
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Mensajes de errores </span>
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Cuando intente editar un usuario o administrador y reciba un mensaje de error,
            esto puede deberse a diferentes motivos (conexión a internet, un campo mal llenado o la 
            contraseña es incorrecta).
            Visite la sección de <span className="fw-bold">Preguntas frecuentes</span> para 
            obtener más información.
          </p>
        </div>
    },


  ]