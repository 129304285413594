import exportsImg from "../../../assets/img/help-export/exports.png";
import academicAreasImg from "../../../assets/img/help-export/export-courses.png";
import pudralLogin from "../../../assets/img/help-export/login-drupal.jpg";
import contentDrupalTable from "../../../assets/img/help-export/content-drupal.jpg";
import editDrupalHome from "../../../assets/img/help-export/edit-drupal-page.png";
import editDrupalHome2 from "../../../assets/img/help-export/edit-drupal-page-2.png";
import homeDrupal from "../../../assets/img/help-export/home-drupal.png";
import drupalCourses from "../../../assets/img/help-export/drupal-courses.png";
import { PageImage } from "../../../components/general/PageImage";
import { selfProjectUrl, pudralUrl } from '../constants';
import { ListContent } from "../../../components/general/ListContent";

const stepsFieldData = [
  {
    title: "Ubicación contenido secundario",
    content: <span>Este selector debe estar en <b>oculto</b>.</span>,
  },
  {
    title: "Opciones de publicación",
    content: <ListContent list={[
      {
        title: "Publicado",
        content: <span>La casilla <b>Publicado</b> debe estar <b>seleccionada</b>.</span>
      },
      {
        title: "Promocionado a la página principal",
        content: <span>La casilla <b>Promocionado a la página</b> debe estar <b>seleccionada</b>.</span>
      },
    ]}/>,
  },
]

export const stepsExportDrupalCourses = [
  {
      title:
        <span className='text-uni'>
          <b>Paso 1:</b> Inicie sesión
        </span>,
      content:
      <div className="px-3 py-3">
        <p>
          Para mostrar las áreas académicas o cursos, es necesario copiar y pegar un código HTML 
          dentro de tres páginas en Drupal.
        </p>
        <p>
          Diríjase a la web <a href={selfProjectUrl}>{selfProjectUrl}</a> e inicie sesión.
        </p>
      </div>,
  },

  {
    title:<span className='text-uni'>
            <b>Paso 2:</b> Diríjase a las <span className="fw-bold"> Exportaciones </span>
          </span>,
    content:
      <div className="px-3 py-3">
        <PageImage
          src={exportsImg}
          title={"Exportaciones"}
          alt={"Exportaciones"}
        />
      </div>
    ,
  },


  {
    title:
      <span className='text-uni'>
        <b>Paso 3:</b>  Seleccione el tipo de exportación (Cursos por facultad), y 
        de clic en <b>Copiar al portapapeles</b>
      </span>
    ,
    content:
          <div className="px-3 py-3">
            <PageImage
              src={academicAreasImg}
              title={"Export to clipboard"}
              alt={"Export to clipboard"}
            />
          </div>
    ,
  },



  {
    title:
      <span className='text-uni'>
        <b>Paso 4:</b>  Diríjase a <a href={`${pudralUrl}/user`}>{pudralUrl}/user</a> e
        inicie sesión
      </span>
    ,
    content:
      <div className="px-3 py-3">
        <PageImage
            src={pudralLogin}
            title={"Drupal login"}
            alt={"Drupal login"}
          />
      </div>
    ,
  },


  {
    title:
      <span className='text-uni'>
        <b>Paso 5:</b> De clic en <b>Contenido</b> y luego haga clic en <b>Cursos y diplomados</b>
      </span>
    ,
    content:
      <div className="px-3 py-3">
        <PageImage
            src={contentDrupalTable}
            title={"Content table drupal"}
            alt={"ontent table drupal"}
          />
      </div>
    ,
  },



  {
    title:
      <span className='text-uni'>
        <b>Paso 6:</b> De clic en <b>Editar</b>, luego clic en <b>Source</b> del editor HTML, y
        finalmente pegue el código copiado del paso 3 en la caja de texto
      </span>
    ,
    content:
      <div className="px-3 py-3">
        <PageImage
            src={editDrupalHome}
            title={"Edit drupal page"}
            alt={"Edit drupal page"}
          />
      </div>
    ,
  },



  {
    title:
      <span className='text-uni'>
        <b>Paso 7:</b> Asegúrese de que las opciones que se indican a continuación estén
        establecidas como corresponde
      </span>
    ,
    content:

      <div className="px-3 py-3">
        <ListContent list={stepsFieldData} />

        <PageImage
          src={editDrupalHome2}
          title={"Edit drupal page"}
          alt={"Edit drupal page"}
        />
      </div>
    ,
  },



  {
    title:
      <span className='text-uni'>
        <b>Paso 8:</b> De clic en guardar (parte inferior de la página)
      </span>
    ,
    content:
      <div className="px-3 py-3">
        <p>
          Para comprobar que todo funciona correctamente, diríjase
          a <a href={pudralUrl}>{pudralUrl}</a> y verifique que las áreas académicas
          se muestran como en la imagen.
        </p>
        <PageImage
            src={homeDrupal}
            title={"Home drupal"}
            alt={"Home drupal"}
          />
      </div>
    ,
  },


  {
    title:
    <span className='text-uni'>
      <b>Paso 9:</b> De clic en alguna facultad. Debe mostrarse una tabla con los
      cursos correspondientes
    </span>
    ,
    content:
      <div className="px-3 py-3">
        <PageImage
            src={drupalCourses}
            title={"Home drupal"}
            alt={"Home drupal"}
          />

        <p className="pt-3">
          Felicidades. En este punto ha configurado correctamente Drupal para
          mostrar los cursos y áreas académicas.
        </p>
      </div>
    ,
  },


];

