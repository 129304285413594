import addCourse1 from '../../../assets/img/addCourse/addCourse1.png'
import addCourse3 from '../../../assets/img/addCourse/addCourse3.png'

import { PageImage } from "../../../components/general/PageImage";
import { Accordion } from '../../../components/general/Accordion'

import { 
  stepsAddCourseData2_1_1,
  stepsAddCourseData2_1_2 
} from '../info/stepsList/stepsAddCourseData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMortarBoard } from '@fortawesome/free-solid-svg-icons'



export const stepsAddCourse = [
    
    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 1: </span> 
        Diríjase a la página de cursos
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            En la barra lateral izquierda, diríjase a la sección de
            <span className="fw-bold"> <FontAwesomeIcon icon={faMortarBoard} className={"fa-md"}/> Cursos </span>
            que se muestra en la siguiente imagen. Podrá observar que existe el botón 
            <span className="fw-bold"> + Agregar curso</span>. Debajo se muestra una barra de búsqueda con todas
            las facultades. Para agregar un nuevo curso puede presionar el botón de 
            <span className="fw-bold"> + Agregar curso</span> o seleccionar alguna facultad de la lista.
          </p>
          <PageImage
            src={addCourse1}
            title={""}
            alt={""}
          />
        </div>
    },



    

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        En caso de presionar el botón
        <span className='fw-bold'> + Agregar curso </span>
      </span>,
      content: 
        <div className="px-3 pt-3">
          <Accordion questions={stepsAddCourseData2_1_1}/>
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        En caso de seleccionar una facultad de la lista
      </span>,
      content: 
        <div className="px-3 pt-3">
          <Accordion questions={stepsAddCourseData2_1_2}/>
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 3: </span>
          Presione el botón aceptar
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Si el formulario se llenó correctamente, se mostrará el siguiente mensaje.
            El curso habrá quedado registrado en el sistema.
          </p>
            
            <p className="fw-bold pt-2">
              Nota: Si marcó la casilla de publicado, el curso será visible para todos en la 
              página principal, de lo contrario el curso solo será visible para los administradores.
            </p>
          <PageImage
            src={addCourse3}
            title={""}
            alt={""}
          />
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Mensajes de errores </span>
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Cuando intente agregar un curso y reciba un mensaje de error,
            esto puede deberse a diferentes motivos (conexión a internet o un campo mal llenado).
             Visite la sección de <span className="fw-bold">Preguntas frecuentes</span> para 
             obtener más información.
          </p>
        </div>
    },


  ]