import { PageImage } from "../../../components/general/PageImage";

import restorePassword1 from '../../../assets/img/restorePassword/restorePassword1.png'
import restorePassword2 from '../../../assets/img/restorePassword/restorePassword2.png'
import restorePassword3 from '../../../assets/img/restorePassword/restorePassword3.png'


export const stepsRestorePassword = [
    
    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 1: </span> 
        Verifique su contraseña
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Si intenta acceder al sistema y recibe siguiente mensaje (mostrado en la imagen), 
            significa que la contraseña ingresada es incorrecta.
          </p>
            
            <p>
              Lo primero que debe de verificar es que la contraseña ingresada esté escrita correctamente 
              (respetar mayúsculas y minúsculas, carácteres o números).
            </p>

            <p>
              Si esto solucionó el problema, significa que ya ha ingresado al sistema. Si esto no solucionó
              el problema, puede seguir al paso 2 (Restaurar contraseña).
            </p>
          <PageImage
            src={restorePassword1}
            title={""}
            alt={""}
          />
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 2: </span>
        Restaure su contraseña
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            En la parte inferior de la imagen, se muestra un texto con el mensaje 
            <span className="fw-bold"> ¿Olvidó su contraseña?</span>. Debe dar clic sobre el texto,
            que lo enviará a otra págia donde podrá ingresar su dirección de correo.
          </p>

          <p className="fw-bold">
            Nota: Es importante que la dirección de correo ingresada sea la misma con la que accede al sistema,
            ya que se le enviará por este mismo un mensaje con su nueva contraseña para acceder al sistema.
          </p>
          <PageImage
            src={restorePassword2}
            title={""}
            alt={""}
          />
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 3: </span>
        Ingrese su dirección de correo electrónico
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Ingrese su dirección de correo electrónico con la que accede al sistema. Posteriormente,
            presione el botón <span className="fw-bold"> Enviár correo</span>. Ahora debe revisar su correo,
            ya que recibirá un mensaje con su nueva contraseña para acceder al sistema.
          </p>

          <PageImage
            src={restorePassword3}
            title={""}
            alt={""}
          />
        </div>
    },



    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Paso 4: </span>
          Acceda al sistema con su nueva contraseña
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Una vez recibido el correo con su nueva contraseña, debe acceder ingresando su dirección de correo 
            y la nueva contraseña.
          </p>
        </div>
    },

    {
      title: 
      <span className='text-uni'>
        <span className='fw-bold'>Mensajes de errores </span>
      </span>,
      content: 
        <div className="px-3 py-3">
          <p>
            Si el sistema no pudo enviar el mensaje a su correo electrónico, puede ser por diferentes motivos 
            (la dirección de correo se escribió incorrectamente, el correo que ingresó no es con 
            el que ingresa al sistema, hay problemas de conexión a internet, entre otros).
            Visite la sección de <span className="fw-bold">Preguntas frecuentes</span> para 
            obtener más información.
          </p>
        </div>
    },


  ]