import { useEffect, useLayoutEffect, useMemo, useState } from "react";

import _ from 'lodash';
import { PageTitle } from "../components/general/PageTitle";
import { PageWithSidebar } from "../components/general/PageWithSidebar";
import { useNavigate, useParams } from "react-router-dom";

import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { TableDetail } from '../components/details/TableDetail'
import { RowDetail } from '../components/details/RowDetail'

import imgCourse from "../assets/img/default-image.png";
import { formatToSimpleDate, formatToUsableDate } from "../functions";
import { useFacultyInfo } from "../hooks/useFacultyInfo";
import useAlert from "../hooks/useAlert";
import { useRedirectIfForbidden } from "../hooks/useRedirectIfForbidden";
import { useCoursesCRUD } from "../hooks/courses/useCoursesCRUD";
import { ExternalFacData } from "../data/categories/ExternalFacData";

const externalFacID = ExternalFacData.id;

const initialValues = {
  where: "---",
  title: "---",
  faculty: "---",
  ri: "---",
  initDate: '---',
  endDate: '---',
  contact: "---",
  tel: "---",
  ext: "---",
  email: "---",
  cost: 0,
  publish: '---',
  img: imgCourse,
};

export const DetailsCoursePage = () => {
  const { courseID } = useParams();
  const navigate = useNavigate();

  const [course, setCourse] = useState(initialValues);
  const { faculty } = useFacultyInfo({ id: course.faculty });

  const { redirectIfForbidden } = useRedirectIfForbidden();
  const { openAlert } = useAlert();
  const { getCourse, deleteCourse } = useCoursesCRUD(true);

  const links = useMemo(() => {
    return [
      { name: "Inicio", path: "/" },
      { name: "Cursos", path: "/courses" },
      { name: faculty.name, path: `/courses/${faculty.id}` },
      { name: "Ver curso", path: `/course/${courseID}` },
    ];
  }, [faculty, courseID]);

  useLayoutEffect(() => {
    onLoadedPage();
  }, []);

  const onLoadedPage = async () => {

    const { ok, response, data, error } = await getCourse(courseID);

    if (ok) {
      const courseInfo = data.course;
      setCourse({
        ...courseInfo,
        initDate: formatToSimpleDate(formatToUsableDate(courseInfo.initDate)),
        endDate: formatToSimpleDate(formatToUsableDate(courseInfo.endDate)),
        publish: courseInfo.publish ? 'Sí' : 'No',
      });
    } else {
      if (response.status === 403) {
        redirectIfForbidden('/courses', true);
      } else {
        openAlert(
          `Error al cargar el curso`,
          <>
            No se ha podido completar la operación.&nbsp;
            {error
              ? <b>{error}</b>
              : "Inténtelo de nuevo más tarde"}.
          </>,
          "error", false, null, null, 4000,
        );
        navigate(-1);
      }
    }
  }

  const onDelete = async (title) => {
    const resp = await openAlert(
      "Eliminar curso",
      <>
        Está apunto de eliminar el curso "<b>{title}</b>".
        <br />
        ¿Desea hacerlo?
      </>,
    )
    if (resp) {
      const { ok, response, error } = await deleteCourse(courseID);
      if (ok) {
        openAlert(
          "Curso eliminado",
          <>
            El curso "<b>{title}</b>" se ha eliminado
            correctamente.
          </>,
          "success", false, null, null, 4000,
        );
        navigate(-1, { replace: true });
      } else {
        openAlert(
          "Error en la eliminación",
          <>
            No se ha podido completar la eliminación.
            {error
              ? <b>{error}</b>
              : "Inténtelo de nuevo más tarde"}.
          </>,
          "error", false, null, null, 4000,
        )
      }
    }
  };

  return (

    <PageWithSidebar links={links}>

      <PageTitle>Detalles del curso</PageTitle>

      <TableDetail>
        {
          course.where && faculty.id === externalFacID &&
          <RowDetail
            header="Organización / Institución" fields={[course.where]} colSpan={2}
          />
        }
        <RowDetail
          header="Título del programa" fields={[course.title]} colSpan={2}
        />
        <RowDetail
          header="Objetivo del programa" fields={[course.objective]} colSpan={2}
        />
        <RowDetail
          header="Facultad" fields={[faculty.name]} colSpan={2}
        />
        <RowDetail
          header="Registro Institucional" fields={[course.ri]} colSpan={2}
        />
        <RowDetail
          header="Fecha de inicio y terminación"
          fields={[course.initDate, course.endDate]}
          colSpan={1}
        />
        <RowDetail
          header="Costo" fields={[`$ ${course.cost} MXN`]} colSpan={2}
        />
      </TableDetail>

      <TableDetail>
        <RowDetail
          header="Contacto" fields={[course.contact]} colSpan={2}
        />
        <RowDetail
          header="Teléfono y extensión"
          fields={[course.tel, course.ext]}
          colSpan={1}
        />
        <RowDetail
          header="Email" fields={[course.email]} colSpan={2}
        />
      </TableDetail>

      <TableDetail>
        <RowDetail
          header="Publicado" fields={[course.publish]} colSpan={2}
        />
      </TableDetail>

      <div className="mb-3">
        <img
          src={course.img || imgCourse}
          id="imgCurso"
          className="img-fluid mx-auto d-block"
          alt="Imagen del curso"
        />
      </div>

      <ActionFormButtonGroup>
        <ActionFormButton
          classType='info'
          type="button"
          value="Editar"
          onClick={() => navigate(`/edit/course/${faculty.id}/form/${courseID}`)}
        />
        <ActionFormButton
          classType='danger'
          type="button"
          value="Borrar"
          onClick={() => onDelete(course.title)}
        />
      </ActionFormButtonGroup>

    </PageWithSidebar>

  );
};
