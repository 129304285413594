import UserProvider from "../providers/UserProvider";
import { TokenProvider } from "../providers/TokenProvider";
import { FacultiesProvider } from "../providers/FacultiesProvider";
import { CheckUserRoutes } from "./CheckUserRoutes";
import { SidebarProvider } from "../providers/SidebarProvider";

const AdminRoutes = (props) => {

  return (
    <UserProvider>
      <TokenProvider>
        <FacultiesProvider>
          <SidebarProvider>
            <CheckUserRoutes />
          </SidebarProvider>
        </FacultiesProvider>
      </TokenProvider>
    </UserProvider>
  );
};

export default AdminRoutes;
