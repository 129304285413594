export const frequentQuestions = [

  {
    title: <span className="fw-bold text-uni">¿Por qué no puedo agregar un usuario?</span>,
    content: 
      <div className="px-3 pt-3">
        <p>
          Para agregar un nuevo usuario o administrador debe verificar que sea administrador, ya que
          <span className="fw-bold"> solo los administradores pueden agregar, modificar o eliminar a
          otros usuarios y administradores</span>.
        </p>

        <p>
          Si es administrador y no puede agregar un usuario o administrador, debe verificar que los datos que ingrese 
          son correctos: el nombre de la persona es un nombre real, se le asignó un rol (Administrador o Usuario) o 
          la dirección de correo sea una dirección real de correo electrónico.
        </p>
      </div>
    ,
  },
  {
    title: <span className="fw-bold text-uni">¿Por qué no puedo agregar un curso desde el formulario?</span>,
    content: 
      <div className="px-3 pt-3">
        <p>
          Para agregar un nuevo curso de alguna facultad, debe verificar que dicha facultad se le
          haya sido asignada a su usuario. <span className="fw-bold"> Solo los administradores pueden agregar,
          editar o modificar todos los cursos de cualquier facultad.</span>
        </p>

        <p>
          Si tiene asignada dicha facultad y no puede agregar un nuevo curso, debe verificar que los datos
          que ingrese son correctos.
        </p>
      </div>
  },

  {
    title: <span className="fw-bold text-uni">¿Por qué no puedo agregar un curso desde un archivo Excel?</span>,
    content: 
      <div className="px-3 pt-3">
        <p>
          Para agregar un nuevo curso de alguna facultad, debe verificar que dicha facultad se le
          haya sido asignada a su usuario. <span className="fw-bold"> Solo los administradores pueden agregar,
          editar o modificar todos los cursos de cualquier facultad.</span>
        </p>

        <p>
          Si tiene asignada dicha facultad y no se puede agregar cursos desde el archivo Excel, debe verificar
          que los campos en el archivo sean correctos y verificar que se está adjuntando en la página. Una
          plantilla del archivo Excel se puede encontrar en la misma página en donde se debe subir. En esta
          se encuentran todos los campos del curso.
        </p>

        <p>
          Para poder subir <b>imágenes de los cursos</b> desde un archivo ZIP, cada imagen dentro del archivo
          comprimido debe de tener como nombre <b>el número de la fila al curso que corresponde</b>.
        </p>
      </div>
  },


  ]