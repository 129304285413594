import { format } from "date-fns";
import { roles } from "../data/role";

const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const debugMode = false;

export function formatRole(rol = "") {
  if (roles[rol]) {
    return roles[rol];
  }
  return "Unknown role";
}

export function formatToSimpleDate(stringDate = "") {
  try {
    return format(new Date(stringDate), "dd/MM/yyyy");
  } catch (error) {
    debugMode && console.log("Formatting stringDate failed. Default case returned.");
    return new Date().toLocaleDateString("es-MX", dateOptions);
  }
}

export function formatToUsableDate(stringDate = "2022-07-29") {
  try {
    const [ year, month, day ] = stringDate.split('T')[0].split('-');
    return new Date(year, month-1, day);
  } catch (error) {
    debugMode && console.log("Formatting stringDate failed. Default case returned.");
    return new Date().toLocaleDateString("es-MX", dateOptions);
  }
}

export function formatToISODate(stringDate = "") {
  try {
    return new Date(stringDate).toISOString();
  } catch (error) {
    debugMode && console.log("Formatting stringDate failed. Default case returned.");
    return new Date().toISOString();
  }
}

// srly
export function formatToUploadableDate(date) {
  if (date) {
    return format(date, "yyyy-MM-dd");
  }
  return date;
}

export function getFaculty(prop, value, array) {
  return array.find((e) => e[prop] === value);
}

// ultra copy-pasteado
export async function URL2File(imageURL, name) {
  const response = await fetch(imageURL);
  const blob = await response.blob();
  const file = new File([blob], name, { type: blob.type });
  // console.log(file);
  return file;
}

export function obtainRole(role) {
  if (role === "ADMIN_ROLE") {
    return "Administrador";
  } else if (role === "SUPER_ADMIN") {
    return "Super Administrador";
  } else {
    return "Usuario";
  }
}

export function isBackError(error) {
  return typeof (error.response.data === "String");
}

export const createRoute = (path = "", element = null, roles = []) => ({
  path,
  element,
  roles,
});

export const createRedirect = (path = "", redirectTo = "", roles = []) => ({
  path,
  redirectTo,
  roles,
});

export function log(flag, msg) {
  if (flag && debugMode) console.log(msg);
}