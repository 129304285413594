import { PageImage } from "../../../../components/general/PageImage";
import { ListContent } from '../../../../components/general/ListContent'



import addCourseByFile2_1_1 from '../../../../assets/img/addCourse/addCourse2.1.1.png'
import addCourseByFile2_1_2 from '../../../../assets/img/addCourseByFile/addCourseByFile2.1.2.png'

import addCourseByFile2_2_1 from '../../../../assets/img/addCourse/addCourse2.2.1.png'
import addCourseByFile2_2_2 from '../../../../assets/img/addCourse/addCourse2.2.2.png'
import addCourseByFile2_2_3 from '../../../../assets/img/addCourseByFile/addCourseByFile2.2.2.png'
import addCourseByFile2_1_2_1 from '../../../../assets/img/addCourseByFile/addCourseByFile2.1.2.1.png'
import addCourseByFile2_1_2_2 from '../../../../assets/img/addCourseByFile/addCourseByFile2.1.2.2.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";





export const stepsAddCoursOptionseData = [
    {
      title: <span className="fw-bold">Llenar formulario</span>,
      content: <span>Le mostrará un formulario para registrar los datos del curso.</span>,
    },
    {
      title: <span className="fw-bold">Subir un archivo en formato EXCEL</span>,
      content: <span>Esta opción permite añadir un archivo Excel que contiene los datos de los cursos
      y un archivo comprimido ZIP con las imágenes de los cursos. 
      </span>,
    },
  ];
  

  

  
export const stepsAddCourseByFileData = [
    {
      title: <span className="fw-bold">Título (titulo) (obligatorio)</span>,
      content: <span>Título del programa que describirá al curso.</span>,
    },
    {
      title: <span className="fw-bold">Facultad (facultad_nombre) (obligatoria)</span>,
      content: 
      <>
      <span>
        Al seleccionar la celda por debajo de este título, saldrá una flecha a un lado derecho.
        Al dar clic en esta flecha, saldrá una lista de facultades, en la que podrá seleccionar alguna.
      </span>
      <br />
      <span>
        <b>Nota: Al seleccionar una facultad, el campo llamado "facultad" (al lado derecho)
        se llenará automáticamente, por lo que se recomienda no modificarlo directamente.</b>
      </span>
      </>,
    },
    {
      title: <span className="fw-bold">Registro Institucional (registro_institucional) (obligatorio)</span>,
      content: <span>
        Deberá ser único y correspondiente a la facultad seleccionada.
      </span>,
    },
    {
      title: <span className="fw-bold">Fecha de inicio (fecha_inicio) y Fecha de terminación (fecha_final) (obligatorias)</span>,
      content: <span>
        El formato de las fechas es YYYY-MM-DD, que corresponde al año, el mes, y el día del mes respectivamente.
        Además, las fechas deberán tener coherencia. Por ejemplo, un curso no puede iniciar el 2022-11-11 y
        terminar el 2022-06-07.
      </span>,
    },
    {
      title: <span className="fw-bold">Contacto (contacto) (obligatorio)</span>,
      content: <span>
        Es el nombre del responsable del curso.
      </span>,
    },
    {
      title: <span className="fw-bold">Teléfono (telefono) (obligatorio)</span>,
      content: <span>
        Es el número de teléfono del responsable del curso. Debe ser un número telefónico real, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },
    {
      title: <span className="fw-bold">Extensión (extension)</span>,
      content: <span>
        Si el número de teléfono requiere una extensión para localizarlo, entonces se debe ingresar.
      </span>,
    },
    {
      title: <span className="fw-bold">Correo electrónico (correo) (obligatorio)</span>,
      content: <span>
        Es el correo electrónico del responsable del curso. Debe ser un correo vigente, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },

    {
      title: <span className="fw-bold">Costo del curso (precio)</span>,
      content: <span>
        Deberá ser una cantidad positiva, puede tener un valor numérico o simplemente escribir el costo en cero (0)
        cuando el curso sea gratis.
      </span>,
    },

    {
      title: <span className="fw-bold">Imagen del curso (img)</span>,
      content:
      <>
      <span>
        En este campo se puede colocar un enlace (dirección URL) a la imágen. No es obligatorio.
      </span>
      <br />
      <span>
        <b>Nota: En el siguiente paso se muestra otra opción para subir imágenes a los cursos.</b>
      </span>
      <br />
      <span>
        <b>Nota 2: Si no se va a ingresar ningún URL, deje el campo vacío.</b>
      </span>
      </>,
    },


    {
      title: <span className="fw-bold">Publicado (publicado)</span>,
      content: <span>
        Al seleccionar la celda por debajo del apartado, se mostrará una flecha. Al dar clic en
        esta flecha, se mostrarán las opciones <b>true</b> y <b>false</b>. La primera indica que
        el curso <b>será publicado en cuanto sea subido</b>, mientras que la segunda indica que
        el curso <b>estará subido en el sistema</b>, pero <b>no estará publicado</b>.
      </span>,
    },
  ];

  
export const stepsAddCourseByFileData2_1_1 = [
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.1: </span> Observe las opciones para agregar un curso
            </span>
      ,
      content: 
            <div className="px-3 py-3">
              <p>
                Al presionar el botón <span className='fw-bold'> + Agregar curso </span>
                le dará la opción de elegir entre dos formas de agregar cursos:
              </p>
              <ListContent list={stepsAddCoursOptionseData}/>
  
              <p>
                Elija la opción <span className='fw-bold'>Subir un archivo en formato EXCEL</span>.
              </p>
              <PageImage
                src={addCourseByFile2_1_1}
                title={""}
                alt={""}
              />
            </div>
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.2: </span> Observe el formulario para subir archivos
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Después de seleccionar la opción <span className='fw-bold'> Subir un archivo en formato EXCEL </span>
                le mostrará el siguiente formulario.
              </p>
  
              <PageImage
                src={addCourseByFile2_1_2}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.3: </span> Descargue la plantilla, y llene el archivo EXCEL
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Después de presionar el botón <b><FontAwesomeIcon icon={faFileDownload} className={"fa-md"}/> Descargar plantilla</b>,
                abra el archivo Excel y rellene los campos. Cada fila del archivo corresponde a <b>un curso</b>.
              </p>
              <p>
                <b>Nota: Si quiere agregar más de un curso, duplique la segunda fila con los campos llenados la cantidad de veces
                que sea necesaria.</b>
              </p>
              <p>
                Los datos que tiene que llenar son los siguientes:
              </p>
  
              <ListContent list={stepsAddCourseByFileData}/>
  
              <PageImage
                src={addCourseByFile2_1_2_2}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.4: </span> Cree un archivo ZIP con las imágenes a subir (opcional)
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                En caso de que quiera subir las imágenes de los cursos del archivo Excel, pero no tenga un
                enlace URL para cada una, puede hacerlo de la siguiente forma.
              </p>
              <p>
                Cada imagen debe tener como nombre de archivo <b>el número de la fila que le corresponde a su
                curso en el archivo Excel</b>. Es decir, el primer curso del archivo estaría en la <b>fila 2</b>,
                por lo que su imagen debería tener como nombre "<b>2</b>".
              </p>
              <p>
                Una forma de hacer un ZIP rápidamente es seleccionando todos los archivos, dar clic derecho sobre
                estos, dirigirse a "Enviar a" y seleccionar "Carpeta comprimida (en zip)".
              </p>
  
              <PageImage
                src={addCourseByFile2_1_2_1}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.5: </span> Suba los archivos
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Luego de haber hecho los pasos anteriores, ya puede subir los archivos.
              </p>
            </div> 
      ,
    },
  ];
  




  export const stepsAddCourseByFileData2_1_2 = [
    {
      title:
            <span className='text-uni'>
              <span className='fw-bold'>Paso 2.1: </span> Observe la lista de cursos por facultad específica
            </span>
      ,
      content:
        <div className="px-3 py-3">
          <p>
              Si selecciona una facultad, le mostrará una lista con los cursos
              activos e inactivos. Presione el botón <span className="fw-bold"> + Agregar curso</span>.
            </p>
            <PageImage
              src={addCourseByFile2_2_1}
              title={""}
              alt={""}
            />
        </div>
      ,
    },
        {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.2: </span> Observe las opciones para agregar un curso
            </span>
      ,
      content: 
            <div className="px-3 py-3">
              <p>
                Al presionar el botón <span className='fw-bold'> + Agregar curso </span>
                le dará la opción de elegir entre dos formas de agregar cursos:
              </p>
              <ListContent list={stepsAddCoursOptionseData}/>
  
              <p>
                Elija la opción <span className='fw-bold'>Subir un archivo en formato EXCEL</span>.
              </p>
              <PageImage
                src={addCourseByFile2_2_2}
                title={""}
                alt={""}
              />
            </div>
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.3: </span> Observe el formulario para subir archivos
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Después de seleccionar la opción <span className='fw-bold'> Subir un archivo en formato EXCEL </span>
                le mostrará el siguiente formulario.
              </p>
  
              <PageImage
                src={addCourseByFile2_2_3}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.4: </span> Descargue la plantilla, y llene el archivo EXCEL
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Después de presionar el botón <b><FontAwesomeIcon icon={faFileDownload} className={"fa-md"}/> Descargar plantilla</b>,
                abra el archivo Excel y rellene los campos. Cada fila del archivo corresponde a <b>un curso</b>.
              </p>
              <p>
                <b>Nota: Si quiere agregar más de un curso, duplique la segunda fila con los campos llenados la cantidad de veces
                que sea necesaria.</b>
              </p>
              <p>
                Los datos que tiene que llenar son los siguientes:
              </p>
  
              <ListContent list={stepsAddCourseByFileData}/>
  
              <PageImage
                src={addCourseByFile2_1_2_2}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.5: </span> Cree un archivo ZIP con las imágenes a subir (opcional)
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                En caso de que quiera subir las imágenes de los cursos del archivo Excel, pero no tenga un
                enlace URL para cada una, puede hacerlo de la siguiente forma.
              </p>
              <p>
                Cada imagen debe tener como nombre de archivo <b>el número de la fila que le corresponde a su
                curso en el archivo Excel</b>. Es decir, el primer curso del archivo estaría en la <b>fila 2</b>,
                por lo que su imagen debería tener como nombre "<b>2</b>".
              </p>
              <p>
                Una forma de hacer un ZIP rápidamente es seleccionando todos los archivos, dar clic derecho sobre
                estos, dirigirse a "Enviar a" y seleccionar "Carpeta comprimida (en zip)".
              </p>
  
              <PageImage
                src={addCourseByFile2_1_2_1}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.6: </span> Suba los archivos
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Luego de haber hecho los pasos anteriores, ya puede subir los archivos.
              </p>
            </div> 
      ,
    },
  ];