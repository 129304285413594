
export const PageImage = ({ src, title, alt, className = '' }) => {
    return (
        <img
            src={src}
            className={`img-fluid img-border ${className}`}
            title={title}
            alt={alt}
        />
    );
}
