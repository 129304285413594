import { useLayoutEffect, useRef, useState } from "react";
import { LargeButton } from "../general/LargeButton";
import innerDefaultImage from './../../assets/img/default-image.png';

export const FormImageSelectInput = ({
    label,
    name,
    value,
    src = '',
    required = false,
    onChange,
    onClickRemove,
    altText,
    validFeedback,
    invalidFeedback,
    defaultImage
}) => {

    const img = useRef();

    useLayoutEffect(() => {
        img.current.src = src.length > 0
            ? src
            : defaultImage ?? innerDefaultImage;
    }, [src]);

    const display = invalidFeedback?.length > 0 ? 'd-block' : 'd-none';

    return (
        <div className="py-2">
            {label && <label htmlFor={name} className="form-label">
                {label}{required && <span className="text-danger">&nbsp;*</span>}
            </label>}
            <div className="text-center">
                <img
                    ref={img}
                    // id="imgSelected"
                    className="img-fluid"
                    alt={altText}
                />
            </div>

            <input
                id={name}
                name={name}
                type="file"
                className="input-file mt-3"
                accept="image/png, image/jpeg"
                onChange={onChange}
                required={required}
            />

            {(src.length > 0 || value) &&
                <LargeButton
                    value="Borrar imagen"
                    classType="danger"
                    onClick={onClickRemove}
                />
            }

            <div className="valid-feedback">
                {validFeedback}
            </div>
            <div className={`invalid-feedback ${display}`}>
                {invalidFeedback}
            </div>
        </div>
    )
}
