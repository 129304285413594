import { useContext, useEffect, useMemo } from "react";
import { getFaculty } from "../functions";
import { FacultiesContext } from "../providers/FacultiesProvider";

const initialValue = { facultad: '-----', _id: '-----' };

export const useFacultyInfo = ({ id = '', name = '' }) => {

    const { faculties, isLoading, loadFaculties } = useContext(FacultiesContext);

    const mapFaculty = () => {
        const faculty = (id.length > 0
            ? getFaculty('_id', id, faculties)
            : getFaculty('facultad', name, faculties)
        ) ?? initialValue;

        return {
            id: faculty._id,
            name: faculty.facultad
        };
    }

    const faculty = useMemo(() => {
        return mapFaculty();
    }, [id, name, faculties]);

    return {
        faculty,
        faculties,
        isLoading,
        loadFaculties,
    };
}

