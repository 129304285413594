import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleDown,
    faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

export const ListContent = ({
    list,
}) => {

    return (
        <ol className="list-group-numbered p-0">
            {list.map((item, index) => {
                return (
                    <li className="list-group-item d-flex justify-content-between align-items-start" key={index}>
                        <div className="me-auto pb-2">
                            <div className="ms-2 fw-bold">{item.title}</div>
                            <div className='ms-2'>{item.content}</div>
                        </div>
                    </li>
                )
            })}
        </ol>
    )
}