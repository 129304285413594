import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHome, faUser, faMortarBoard, faFileCode, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const iconStyle = "fa-xl nav-link-icon"
const classStyle = ""

export const SidebarData = [
    {
        title: 'Inicio',
        name: "home",
        visibleToUser: true,
        path: '/',
        icon: <FontAwesomeIcon icon={faHome} className={iconStyle} />,
        cName: classStyle
    },
    {
        title: 'Usuarios',
        name: "users",
        visibleToUser: false,
        path: '/users',
        icon: <FontAwesomeIcon icon={faUser} className={iconStyle} />,
        cName: classStyle
    },
    {
        title: 'Cursos',
        name: "courses",
        visibleToUser: true,
        path: '/courses',
        icon: <FontAwesomeIcon icon={faMortarBoard} className={iconStyle} />,
        cName: classStyle
    },
    {
        title: 'Exportaciones',
        name: "exports",
        visibleToUser: false,
        path: '/exports',
        icon: <FontAwesomeIcon icon={faFileCode} className={iconStyle} />,
        cName: classStyle
    },
    {
        title: 'Ayuda',
        name: "help",
        visibleToUser: true,
        path: '/help',
        icon: <FontAwesomeIcon icon={faQuestionCircle} className={iconStyle} />,
        cName: classStyle
    },
]