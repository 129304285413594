import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNavicon } from '@fortawesome/free-solid-svg-icons'


const navigation = () => {
    const sidebar = document.querySelector(".sidebar")

    if (sidebar.classList.contains("active"))
        sidebar.classList.remove("active");
    else
        sidebar.classList.add("active");
};


function Stickybutton() {
    return (
        <div className="sticky-top">
            <button 
            className="btn px-1 py-1 open-btn position-absolute end-0" 
            id="btnOpen" 
            onClick={navigation}
            >
                <FontAwesomeIcon icon={ faNavicon } className={"px-2 fa-xl"}/>
            </button>
        </div>
    )
}

export default Stickybutton