import { useEffect, useLayoutEffect, useMemo, useState } from "react";

import _ from 'lodash';
import { PageTitle } from "../components/general/PageTitle";
import { PageWithSidebar } from "../components/general/PageWithSidebar";

import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { TableDetail } from '../components/details/TableDetail'
import { RowDetail } from '../components/details/RowDetail'

import imgUser from "../assets/img/default-user-image.png";

import useAxios from "../hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { useLoading } from "../hooks/useLoading";
import { useFacultyInfo } from "../hooks/useFacultyInfo";
import useAlert from "../hooks/useAlert";
import { obtainRole } from "../functions";
import { useUsersCRUD } from "../hooks/users/useUsersCRUD";
import { useRedirectIfForbidden } from "../hooks/useRedirectIfForbidden";

const dummyData = {
    name: "---",
    email: "---",
    role: "---",
    faculty: "---",
    img: imgUser,
};

export const DetailsUserPage = (props) => {

  const { get, del } = useAxios();
  const { userID } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const [user, setUser] = useState(dummyData);
  const { faculty } = useFacultyInfo({ id: user.faculty });

  const { redirectIfForbidden } = useRedirectIfForbidden();
  const { openAlert } = useAlert();
  const { getUser, deleteUser } = useUsersCRUD(true);

  const links = useMemo(() => {
    return [
      { name: "Inicio", path: "/" },
      { name: "Usuarios y administradores", path: "/users" },
      { name: faculty.name, path: `/users/${faculty.id}` },
      { name: "Ver usuario", path: `/user/${userID}` },
    ];
  }, [faculty, userID]);

  useLayoutEffect(() => {
    onLoadedPage();
  }, []);

  const onLoadedPage = async () => {

    const { ok, response, data, error } = await getUser(userID);

    if (ok) {
      const userInfo = data.user;
      setUser({ ...userInfo });
    } else {
      if (response.status === 403) {
        redirectIfForbidden('/', true);
      } else {
        openAlert(
          `Error al cargar el usuario`,
          <>
            No se ha podido completar la operación.&nbsp;
            {error
              ? <b>{error}</b>
              : "Inténtelo de nuevo más tarde"}.
          </>,
          "error", false, null, null, 4000,
        );
        navigate(-1);
      }
    }
  }

  const onDelete = async (name, role) => {
    const resp = await openAlert(
      <>Eliminar {obtainRole(role)}</>,
      <>
        Está apunto de eliminar el {obtainRole(role)} "<b>{name}</b>".
        <br />
        ¿Desea hacerlo?
      </>,
    )
    if (resp) {
      const { ok, response, error } = await deleteUser(userID);
      if (ok) {
        openAlert(
          <>{obtainRole(role)} eliminado</>,
          <>
            El {obtainRole(role)} "<b>{name}</b>" se ha eliminado
            correctamente.
          </>,
          "success", false, null, null, 4000,
        );
        navigate(-1, { replace: true });
      } else {
        openAlert(
          "Error en la eliminación",
          <>
            No se ha podido completar la eliminación.
            {error
            ? <b>{error}</b>
            : "Inténtelo de nuevo más tarde"}.
          </>,
          "error", false, null, null, 4000,
        )
      }
    }
  };

  return (

    <PageWithSidebar links={links}>
      <PageTitle>Detalles del usuario</PageTitle>

      <div className="row g-3 ">
          <div className="col-md-4">
            <div className="text-center mb-md-3">
                <img src={user.img || imgUser} 
                  id="imgSelected" 
                  className="img-fluid img-user-size img-border"
                  alt="Seleccione una imagen para el curso"
                />
              </div>
          </div>
          <div className="col-md-8">
            <TableDetail>
                <RowDetail
                  header = "Nombre" fields = {[user.name]} colSpan = {2}
                />
                <RowDetail
                  header = "Correo electrónico" fields = {[user.email]} colSpan = {2}
                />
                <RowDetail
                  header = "Rol" 
                  fields = {[obtainRole(user.role)]} 
                  colSpan = {2}
                />
                <RowDetail
                  header = "Facultad o cargo" fields = {[faculty.name]} colSpan = {2}
                />
            </TableDetail>
          </div>
      </div>

      <ActionFormButtonGroup>
      <ActionFormButton 
          classType='info' 
          type="button" 
          value="Editar" 
          onClick={() => navigate(`/edit/user/${faculty.id}/form/${userID}`)} 
        />
        <ActionFormButton 
          classType='danger' 
          type="button" 
          value="Borrar" 
          onClick={() => onDelete([user.name], [user.role])} 
        />
      </ActionFormButtonGroup>

    </PageWithSidebar>

  );
};