import { createContext, useMemo, useState } from "react"

const defaultValue = {
    show: true,
}

export const SidebarContext = createContext(defaultValue);

export const SidebarProvider = ({ children }) => {
    const [showingSidebar, setShowingSidebar] = useState(defaultValue);

    const value = useMemo(() => ({ showingSidebar, setShowingSidebar }), [showingSidebar]);

    return (
        <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
    )
}