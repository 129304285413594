import React, { createContext, useMemo, useState } from "react";
import { Loading } from "../components/Loading/Loading";

export const LoadingContext = createContext({
  loading: false,
  setLoading: () => {},
});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  
  const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);
  return (
    <LoadingContext.Provider value={value}>
      {children}
      <Loading loading={loading} />
    </LoadingContext.Provider>
  );
};
