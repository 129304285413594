import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { RowDetail } from "../components/details/RowDetail";
import { TableDetail } from "../components/details/TableDetail";
import { CodeTextArea } from "../components/general/CodeTextArea";
import { LargeButton } from "../components/general/LargeButton";
import { PageParagraph } from "../components/general/PageParagraph";
import { PageTitle } from "../components/general/PageTitle";
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import { ExportsData } from "../data/exports/ExportsData";
import useAlert from "../hooks/useAlert";
import useAxios from "../hooks/useAxios";
import { useLoading } from "../hooks/useLoading";

const scriptURL = process.env.REACT_APP_EMBED_LOADER_CDN_URL;

export const ExportViewPage = () => {

    const { exportID } = useParams();
    // const navigate = useNavigate();
    const { loading, setLoading } = useLoading();
    const exportData = ExportsData.find(e => e.id === exportID);
    const { get } = useAxios();
    const { openAlert } = useAlert();

    const [code, setCode] = useState("< ... />");

    const links = [
        { name: "Inicio", path: "/" },
        { name: "Exportaciones", path: "/exports" },
        { name: exportData?.name || "¿?", path: "/exports/" + exportID },
    ];

    useEffect(() => {
        if (!!exportData) loadExportedCode();
    }, [])

    const parseThisPlease = (htmlString, { _id, url }) => {
        const htmlCode = (new DOMParser()).parseFromString(htmlString, 'text/html');

        const div = htmlCode.body.getElementsByTagName('div')[0];
        div.dataset['pudral'] = _id;

        const placeholderWorkaround = 'FCC2022ECONTINUA';
        const script = htmlCode.body.getElementsByTagName('script')[0];
        script.src = placeholderWorkaround;

        return htmlCode.body.innerHTML.replace(placeholderWorkaround, url);
    };

    const loadExportedCode = async () => {
        try {
            setLoading(true);
            let response = await get(`public/drupalpage`);
            if (response.status === 200) {
                const actualExport = response.data.items.find(
                    i => i.name === exportData.pathname
                );
                if (!actualExport) throw new Error("No se encontró una exportación");

                response = await axios.get(actualExport.html);

                const htmlCode = parseThisPlease(response.data, {
                    _id: actualExport._id,
                    url: scriptURL,
                });

                setCode(htmlCode);
                return;
            }
            console.error(response);
            setCode("An error has ocurred");
        } catch (error) {
            console.error(error);
            setCode("An error has ocurred: \n" + error.message);
        } finally {
            setLoading(false);
        }
    }

    const copyToClipboard = async () => {
        navigator.clipboard.writeText(code);
        // console.log(code);
        const resp = await openAlert(
            "Código copiado",
            <>El código ha sido copiado al <b>portapapeles</b> correctamente.</>,
            "success", true, 'info', null, 4000,
        );
        // console.log(resp);
    }

    if (!exportData) {
        return <Navigate to="/exports" />
    }

    return (
        <PageWithSidebar links={links}>

            <PageTitle>{exportData.name}</PageTitle>
            <PageParagraph>
                {exportData.desc}
            </PageParagraph>

            <TableDetail>
                <RowDetail
                    header="Tipo"
                    fields={[exportData.type]}
                    colSpan={2}
                />
                <RowDetail
                    header="Formato"
                    fields={[exportData.format]}
                    colSpan={2}
                />
            </TableDetail>

            <PageParagraph>
                A continuación se muestra el código exportado.
            </PageParagraph>
            <CodeTextArea
                value={code}
                rows={6}
                disabled={true}
                className={loading ? '' : 'bg-white'}
            />

            <LargeButton value="Copiar al portapapeles" onClick={copyToClipboard} />

        </PageWithSidebar>
    )
}
