import { addDays } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ActionFormButton } from "../components/form/ActionFormButton"
import { ActionFormButtonGroup } from "../components/form/ActionFormButtonGroup"
import { FormCheckInput } from "../components/form/FormCheckInput"
import { FormDateInput } from "../components/form/FormDateInput"
import { FormImageSelectInput } from "../components/form/FormImageSelectInput"
import { FormSelectInput } from "../components/form/FormSelectInput"
import { FormTextInput } from "../components/form/FormTextInput"
import { FormTextAreaInput } from "../components/form/FormTextAreaInput"
import { BodyContainer } from "../components/general/BodyContainer"
import { PageParagraph } from "../components/general/PageParagraph"
import { PageTitle } from "../components/general/PageTitle"
import { PageWithSidebar } from "../components/general/PageWithSidebar"
import { formatToUploadableDate, formatToUsableDate } from "../functions"
import useAxios from "../hooks/useAxios"
import { useFacultyInfo } from "../hooks/useFacultyInfo"
import { useForm } from "../hooks/useForm"
import { useLoading } from "../hooks/useLoading"
import { courseSchema } from "../schemas/courseSchema"

import _ from 'lodash';
import useAlert from "../hooks/useAlert";
import { useRedirectIfForbidden } from "../hooks/useRedirectIfForbidden"
import { useCoursesCRUD } from "../hooks/courses/useCoursesCRUD"
import { roleValues } from "../data/role"
import useMyInfo from "../hooks/useMyInfo"
import { ExternalFacData } from "../data/categories/ExternalFacData"
import imgCourse from "../assets/img/default-image.png"

const externalFacID = ExternalFacData.id;

const initialValues = {
    where: '',
    title: '',
    objective: '',
    faculty: '',
    ri: '',
    initDate: new Date(),
    endDate: addDays(new Date(), 30),
    contact: '',
    tel: '',
    ext: '',
    email: '',
    image: null,
    cost: '',
    publish: [],
};

export const AddCoursePage = () => {

    const { facID, courseID } = useParams();
    const { faculty: facObject } = useFacultyInfo({ id: facID });
    const { setLoading } = useLoading();
    const { get, put, post, del } = useAxios();
    const navigate = useNavigate();

    const { openAlert } = useAlert();
    const { redirectIfForbidden } = useRedirectIfForbidden();
    const { getCourse, addCourse, editCourse, deleteCourseImage } = useCoursesCRUD(true);

    const userData = useMyInfo();

    const isEdit = !!courseID;
    const haveFaculty = !!facID;

    const facPart = haveFaculty ? "/" + facID : '';

    const links = useMemo(() => {
        return [
            { name: "Inicio", path: "/" },
            { name: "Cursos", path: "/courses" },
            ...(haveFaculty ? [{ name: facObject.name, path: `/courses${facPart}` }] : []),
            ...(isEdit ? [
                { name: "Editar curso", path: `/edit/course${facPart}/form/${courseID}` },
            ] : []),
            ...(!isEdit ? [
                { name: "Opciones", path: `/add/course${facPart}/options` },
                { name: "Agregar curso", path: `/add/course${facPart}/form` },
            ] : []),
        ];
    }, [facID, facObject]);

    const {
        where,
        title,
        objective,
        faculty,
        ri,
        initDate,
        endDate,
        contact,
        tel,
        ext,
        email,
        cost,
        publish,
        image,
        onInputChange,
        formState,
        setFormState,
        errors,
        validateFormState,
        hasBeenSubmited,
    } = useForm(
        haveFaculty
            ? { ...initialValues, faculty: facID }
            : initialValues,
        courseSchema,
    );

    const [imageURL, setImageURL] = useState('');
    const [imageInfo, setImageInfo] = useState({
        hadImageBefore: false,
        changedImage: false,
        deletedImage: false,
    });
    const [faculties, setFaculties] = useState([]);

    useEffect(() => {
        loadFaculties();
        if (isEdit) loadCourse();
    }, []);

    useEffect(() => {
        if (!isEdit) checkIfForbidden();
    }, []);

    const checkIfForbidden = async () => {
        if (userData.isUserLoading) await userData.getMyInfo();
        if (userData.rol === roleValues.USER_ROLE && userData.facultad_id !== facID) {
            redirectIfForbidden('/add/course/options', true);
        }
    }

    const loadCourse = async () => {
        const { ok, data, response, error } = await getCourse(courseID);
        if (ok) {
            const course = data.course;
            //// console.log(course);
            setFormState(prev => ({
                ...prev, ...course,
                initDate: formatToUsableDate(course.initDate),
                endDate: formatToUsableDate(course.endDate),
                publish: course.publish ? ['yes'] : [],
                img: undefined, // para evitar confusiones...
            }));
            setImageURL(course.img || '');
            setImageInfo({
                ...imageInfo,
                hadImageBefore: !!course.img,
            });
            return;
        } else {
            if (response.status === 403) {
                redirectIfForbidden('/courses', true);
            } else {
                openAlert(
                    `Error al cargar el curso`,
                    <>
                        No se ha podido completar la operación.&nbsp;
                        {error
                            ? <b>{error}</b>
                            : "Inténtelo de nuevo más tarde"}.
                    </>,
                    "error", false, null, null, 4000,
                );
                navigate(-1);
            }
        }
    }

    const loadFaculties = async () => {
        try {
            setLoading(true);
            const resp = await get(`public/facultades`);
            if (resp.status === 200) {
                const facs = resp.data.items;
                const mapped = facs.map(i => ({
                    value: i._id,
                    option: i.facultad,
                }));
                setFaculties([{
                    value: 'none',
                    option: 'Seleccione una opción'
                }, ...mapped]);
                return;
            }
            // console.log(resp);
        } catch (error) {
            const message = _.get(error, "response.data.errors[0].msg");
            openAlert(
                "Error al cargar facultades",
                <>
                    Ocurrió un error al cargar la lista de facultades.&nbsp;
                    {message
                        ? <b>{message}</b>
                        : "Inténtelo de nuevo más tarde"}.
                </>,
                "error", undefined, undefined, undefined, 4000,
            );
            navigate(-1);
        } finally {
            setLoading(false);
        }
    }

    const onChangeImage = (event) => {
        const img = event.target.files[0];
        if (!!img) {
            onInputChange({ target: { name: 'image', value: img } });

            const url = !!img ? URL.createObjectURL(img) : '';
            setImageURL(url);

            // Esto es para borrar la imagen pasada cuando ya se cambió
            setImageInfo({
                ...imageInfo,
                changedImage: true,
            });
        }
    }

    const onRemoveImage = () => {
        onInputChange({ target: { name: 'image', value: null } });
        setImageURL('');
        setImageInfo({
            ...imageInfo,
            deletedImage: true,
        });
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        //// console.log({ ...formState, image });

        hasBeenSubmited();
        const isValid = validateFormState();
        if (!isValid) {
            openAlert(
                "Errores en los campos",
                <>
                    Por favor revise los campos del formulario
                    e inténtelo nuevamente.
                </>,
                "error", false, null, null, 4000,
            );
            return;
        }

        //* Checar si se necesita borrar imagen pasada
        let delImgResult = true;
        if ((imageInfo.deletedImage || imageInfo.changedImage) && imageInfo.hadImageBefore) {
            //* Borrando imagen que tiene actualmente (antes de subir los cambios de edición)
            // (esto lo hice para borrar las imagenes pasadas antes de quitar/subir una nueva)
            const { ok } = await deleteCourseImage(courseID);
            delImgResult = ok;
        }

        //* Agregar / Editar curso
        const data = {
            ...formState,
            initDate: formatToUploadableDate(initDate),
            endDate: formatToUploadableDate(endDate),
            publish: publish.includes('yes').toString(),
        };

        let result;
        if (!isEdit) {
            result = await addCourse(data);
        } else {
            result = await editCourse(data, courseID);
        }

        if (result.ok) {
            openAlert(
                `Curso ${isEdit ? 'editado' : 'agregado'}`,
                <>
                    El curso "<b>{title}</b>" ha
                    sido {isEdit ? 'editado' : 'agregado'} correctamente.&nbsp;
                    {!delImgResult && <>Sin embargo, no se pudo borrar la imagen.</>}
                </>,
                delImgResult ? "success" : "warning", false, null, null, 4000,
            )
            navigate(-1, { replace: true });
        } else {
            openAlert(
                `Error al ${isEdit ? 'editar' : 'agregar'} el curso`,
                <>
                    No se ha podido completar la operación.&nbsp;
                    {result.error
                        ? <b>{result.error}</b>
                        : "Inténtelo de nuevo más tarde"}.
                </>,
                "error", false, null, null, 4000,
            )
        }
    }

    return (
        <PageWithSidebar links={links}>

            <form className="needs-validation" noValidate onSubmit={onSubmit}>

                <PageTitle>{isEdit ? "Editar" : "Agregar"} curso</PageTitle>
                <PageParagraph>
                    Llene el siguiente formulario.
                </PageParagraph>

                <BodyContainer>

                    <div className="row g-3">

                        <div className="col-md-12">

                            {
                                faculty === externalFacID &&
                                <FormTextInput
                                    label="Nombre de la organización / institución"
                                    name="where"
                                    value={where}
                                    type="text"
                                    required
                                    onChange={onInputChange}
                                    placeholder='Nombre de la organización / institución'
                                    invalidFeedback={errors.where}
                                />
                            }

                            <FormTextInput
                                label="Título"
                                name="title"
                                value={title}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Título del programa'
                                invalidFeedback={errors.title}
                            />

                            <FormTextAreaInput
                                label="Objetivo"
                                name="objective"
                                value={objective}
                                type="text"
                                required
                                rows={4}
                                resizable={false}
                                onChange={onInputChange}
                                placeholder='Objetivo del programa'
                                invalidFeedback={errors.objective}
                            />

                            <FormSelectInput
                                label="Facultad"
                                disabled={haveFaculty}
                                name="faculty"
                                value={faculty}
                                options={faculties}
                                required
                                onChange={onInputChange}
                                invalidFeedback={errors.faculty}
                            />

                            <FormTextInput
                                label="Registro Institucional"
                                name="ri"
                                value={ri}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Registro Institucional del programa'
                                invalidFeedback={errors.ri}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormDateInput
                                label="Fecha de inicio"
                                name="initDate"
                                value={initDate}
                                required
                                onChange={onInputChange}
                                placeholder='DD/MM/AAAA'
                                invalidFeedback={errors.initDate}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormDateInput
                                label="Fecha de terminación"
                                name="endDate"
                                value={endDate}
                                minDate={initDate}
                                required
                                onChange={onInputChange}
                                placeholder='DD/MM/AAAA'
                                invalidFeedback={errors.endDate}
                            />
                        </div>

                        <div className="col-md-12">

                            <FormTextInput
                                label="Contacto"
                                name="contact"
                                value={contact}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Nombre del contacto'
                                invalidFeedback={errors.contact}
                            />

                        </div>

                        <div className="col-md-6">
                            <FormTextInput
                                label="Teléfono"
                                name="tel"
                                value={tel}
                                type="text"
                                required
                                onChange={onInputChange}
                                placeholder='Número telefónico'
                                invalidFeedback={errors.tel}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormTextInput
                                label="Extensión"
                                name="ext"
                                value={ext}
                                type="text"
                                onChange={onInputChange}
                                placeholder='Extensión'
                                invalidFeedback={errors.ext}
                            />
                        </div>

                        <div className="col-md-12">

                            <FormTextInput
                                label="Correo electrónico"
                                name="email"
                                value={email}
                                type="email"
                                required
                                onChange={onInputChange}
                                placeholder='Correo electrónico del contacto'
                                invalidFeedback={errors.email}
                            />

                            <FormTextInput
                                label="Costo"
                                name="cost"
                                value={cost}
                                type="number"
                                onChange={onInputChange}
                                placeholder='Costo del curso ($MXN)'
                                invalidFeedback={errors.cost}
                            />

                            <FormImageSelectInput
                                label="Imagen"
                                name="image"
                                value={image}
                                src={imageURL}
                                altText="Previsualización de imagen"
                                defaultImage={imgCourse}
                                onChange={onChangeImage}
                                onClickRemove={onRemoveImage}
                                invalidFeedback={errors.image}
                            />
                        </div>

                    </div>

                </BodyContainer>

                <PageParagraph>
                    Seleccione la casilla siguiente si quiere que el curso sea <b>público</b>.
                </PageParagraph>

                <BodyContainer>

                    <FormCheckInput
                        name="publish"
                        value={publish}
                        options={[
                            { value: 'yes', option: 'Publicado' },
                        ]}
                        onChange={onInputChange}
                        invalidFeedback={errors.publish}
                    />

                </BodyContainer>

                <ActionFormButtonGroup>
                    <ActionFormButton
                        classType='secondary'
                        type="button"
                        value="Cancelar"
                        onClick={() => navigate(-1)}
                    />
                    <ActionFormButton classType='info' type="submit" value="Aceptar" />
                </ActionFormButtonGroup>

            </form>

        </PageWithSidebar>
    )
}
