import { PageImage } from "../../../../components/general/PageImage";
import { ListContent } from '../../../../components/general/ListContent'


import addCourse2_1_1 from '../../../../assets/img/addCourse/addCourse2.1.1.png'
import addCourse2_1_2 from '../../../../assets/img/addCourse/addCourse2.1.2.png'
import addCourse2_2_1 from '../../../../assets/img/addCourse/addCourse2.2.1.png'
import addCourse2_2_2 from '../../../../assets/img/addCourse/addCourse2.2.2.png'
import addCourse2_2_3 from '../../../../assets/img/addCourse/addCourse2.2.3.png'



export const stepsAddCoursOptionseData = [
    {
      title: <span className="fw-bold">Llenar formulario</span>,
      content: <span>Le mostrará un formulario para registrar los datos del curso.</span>,
    },
    {
      title: <span className="fw-bold">Subir un archivo en formato Excel</span>,
      content: <span>Esta opción permite añadir un archivo Excel que contiene los datos de los cursos
      y un archivo comprimido ZIP con las imágenes de los cursos. 
      </span>,
    },
  ];
  


export const stepsAddCourseData1 = [
    {
      title: <span className="fw-bold">Título (obligatorio)</span>,
      content: <span>Título del programa que describirá al curso. Será visible para todos.</span>,
    },
    {
      title: <span className="fw-bold">Facultad (obligatoria)</span>,
      content: <span>
        Puede elegir cualquier facultad, este curso solo estará disponible para la facultad seleccionada.
      </span>,
    },
    {
      title: <span className="fw-bold">Registro Institucional (obligatorio)</span>,
      content: <span>
        Deberá ser único y correspondiente a la facultad seleccionada.
      </span>,
    },
    {
      title: <span className="fw-bold">Fecha de inicio y Fecha de terminación  (obligatorias)</span>,
      content: <span>
        Las fechas deberán tener coherencia. Por ejemplo, un curso no puede iniciar el 11/11/2022 y terminar
        el 07/06/2022.
      </span>,
    },
    {
      title: <span className="fw-bold">Contacto (obligatorio)</span>,
      content: <span>
        Es el nombre del responsable del curso.
      </span>,
    },
    {
      title: <span className="fw-bold">Teléfono (obligatorio)</span>,
      content: <span>
        Es el número de teléfono del responsable del curso. Debe ser un número telefónico real, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },
    {
      title: <span className="fw-bold">Extensión</span>,
      content: <span>
        Si el número de teléfono requiere una extensión para localizarlo, entonces se debe ingresar.
      </span>,
    },
    {
      title: <span className="fw-bold">Correo electrónico (obligatorio)</span>,
      content: <span>
        Es el correo electrónico del responsable del curso. Debe ser un correo vigente, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },

    {
      title: <span className="fw-bold">Costo del curso</span>,
      content: <span>
        Deberá ser una cantidad positiva, puede tener un valor numérico o simplemente escribir el costo en cero (0)
        cuando el curso sea gratis.
      </span>,
    },

    {
      title: <span className="fw-bold">Imagen del curso</span>,
      content: <span>
        Una imagen para el curso puede hacerlo más llamativo. La imagen para el curso no es requerida. Si no
        se elige una imagen entonces el sistema le asignará una por defecto.
      </span>,
    },


    {
      title: <span className="fw-bold">Casilla "Publicado"</span>,
      content: <span>
        Si marca la casilla de <span className="fw-bold">Publicado</span>, el curso se mostrará
        en la pagina de principal y será visible para todos los usuarios. Si no selecciona la casilla, 
        el curso estará agregado en el sistema, pero no será visible para los usuarios. 
      </span>,
    },
  ];
  



  
  export const stepsAddCourseData2 = [
    {
      title: <span className="fw-bold">Título (obligatorio)</span>,
      content: <span>Título del programa que describirá al curso. Será visible para todos.</span>,
    },
    {
      title: <span className="fw-bold">Facultad (obligatoria)</span>,
      content: <span>
        Selecciona la facultad por defecto y no se puede modificar. Por ejemplo, si entramos en la facultad 
        de medicina, la facultad seleccionada por defecto será la facultad de medicina, y no se podra cambiar.
      </span>,
    },
    {
      title: <span className="fw-bold">Registro Institucional (obligatorio)</span>,
      content: <span>
        Deberá ser único y correspondiente a la facultad seleccionada.
      </span>,
    },
    {
      title: <span className="fw-bold">Fecha de inicio y Fecha de terminación (obligatorias)</span>,
      content: <span>
        Las fechas deberán tener coherencia. Por ejemplo, un curso no puede iniciar el 11/11/2022 y terminar
        el 07/06/2022.
      </span>,
    },
    {
      title: <span className="fw-bold">Contacto (obligatorio)</span>,
      content: <span>
        Es el nombre del responsable del curso.
      </span>,
    },
    {
      title: <span className="fw-bold">Teléfono (obligatorio)</span>,
      content: <span>
        Es el número de teléfono del responsable del curso. Debe ser un número telefónico real, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },
    {
      title: <span className="fw-bold">Extensión</span>,
      content: <span>
        Si el número de teléfono requiere una extensión para localizarlo, entonces se debe ingresar.
      </span>,
    },
    {
      title: <span className="fw-bold">Correo electrónico (obligatorio)</span>,
      content: <span>
        Es el correo electrónico del responsable del curso. Debe ser un correo vigente, ya que será
        uno de los medios por los cuales los interesados del curso podrán contactarse.
      </span>,
    },
  
    {
      title: <span className="fw-bold">Costo del curso</span>,
      content: <span>
        Deberá ser una cantidad positiva, puede tener un valor numérico o simplemente escribir el costo en cero (0)
        cuando el curso sea gratis.
      </span>,
    },
  
    {
      title: <span className="fw-bold">Imagen del curso</span>,
      content: <span>
        Una imagen para el curso puede hacerlo más llamativo. La imagen para el curso no es requerida. Si no
        se elige una imagen entonces el sistema le asignará una por defecto.
      </span>,
    },
  
  
    {
      title: <span className="fw-bold">Casilla "Publicado"</span>,
      content: <span>
        Si marca la casilla de <span className="fw-bold">Publicado</span>, el curso se mostrará
        en la pagina de principal y será visible para todos los usuarios. Si no selecciona la casilla, 
        el curso estará agregado en el sistema, pero no será visible para los usuarios. 
      </span>,
    },
  ];
  


export const stepsAddCourseData2_1_1 = [
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.1: </span>Observe las opciones para agregar un curso
            </span>
      ,
      content: 
            <div className="px-3 py-3">
              <p>
                Al presionar el botón <span className='fw-bold'> + Agregar curso </span>
                le dará la opción de elegir entre dos formas de agregar cursos:
              </p>
              <ListContent list={stepsAddCoursOptionseData}/>
  
              <p>
                Elija la opción <span className='fw-bold'> Llenar el formulario</span>.
              </p>
              <PageImage
                src={addCourse2_1_1}
                title={""}
                alt={""}
              />
            </div>
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.2: </span> Llene el formulario
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Después de seleccionar la opción <span className='fw-bold'> Llenar el formulario </span> 
                le mostrará el siguiente formulario. Para agregar un nuevo curso con el formulario necesita:
              </p>
  
              <ListContent list={stepsAddCourseData1}/>
  
              <PageImage
                src={addCourse2_1_2}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
  ];
  
  
  
  
  
  
  export const stepsAddCourseData2_1_2 = [
    {
      title:
            <span className='text-uni'>
              <span className='fw-bold'>Paso 2.1: </span> Observe la lista de cursos por facultad específica
            </span>
      ,
      content:
        <div className="px-3 py-3">
          <p>
              Si selecciona una facultad, le mostrará una lista con los cursos
              activos e inactivos. Presione el botón <span className="fw-bold"> + Agregar curso</span>.
            </p>
            <PageImage
              src={addCourse2_2_1}
              title={""}
              alt={""}
            />
        </div>
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.2: </span>Observe las opciones para agregar un curso
            </span>
      ,
      content: 
            <div className="px-3 py-3">
              <p>
                Al presionar el botón <span className='fw-bold'> + Agregar curso </span>
                le dará la opción de elegir entre dos formas de agregar cursos:
              </p>
              <ListContent list={stepsAddCoursOptionseData}/>
  
              <p>
                Elija la opción <span className='fw-bold'> Llenar el formulario</span>.
              </p>
              <PageImage
                src={addCourse2_2_2}
                title={""}
                alt={""}
              />
            </div>
      ,
    },
    {
      title: <span className='text-uni'>
              <span className='fw-bold'>Paso 2.3: </span> Llene el formulario
            </span>
      ,
      content:
            <div className="px-3 py-3">
              <p>
                Después de seleccionar la opción <span className='fw-bold'> Llenar el formulario </span>
                le mostrará el siguiente formulario. Para agregar un nuevo curso con el formulario necesita:
              </p>
  
              <ListContent list={stepsAddCourseData2}/>
  
              <PageImage
                src={addCourse2_2_3}
                title={""}
                alt={""}
              />
            </div> 
      ,
    },
  ];
  