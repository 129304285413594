import React, { useState } from "react";
import _ from "lodash";
import useAxios from "../hooks/useAxios";
import { useNavigate, useParams } from "react-router";
import useAlert from "../hooks/useAlert";
import { useLoading } from "../hooks/useLoading";
import { PageWithCenteredSquare } from "../components/general/PageWithCenteredSquare";
import { PageParagraph } from "../components/general/PageParagraph";
import { LargeButton } from "../components/general/LargeButton";
import { restorePassSchema } from "../schemas/restorePassSchema";
import { useForm } from "../hooks/useForm";
import { FormTextInput } from "../components/form/FormTextInput";

export const RestorePasswordPage = (props) => {
  const {
    password,
    repeatPassword,
    onInputChange,
    errors,
    validateFormState,
    hasBeenSubmited
  } = useForm(
    {
      password: "",
      repeatPassword: "",
    },
    restorePassSchema,
  );
  const navigate = useNavigate();
  const params = useParams();
  const { put } = useAxios();
  const { openAlert } = useAlert();
  const { setLoading } = useLoading();

  const handleSubmit = async (event) => {
    event.preventDefault();

    hasBeenSubmited();
    const isValid = validateFormState();
    if (!isValid) {
      openAlert(
        "Errores en los campos",
        <>
          Por favor revise los campos del formulario
          e inténtelo nuevamente.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
      return;
    }

    try {
      setLoading(true);
      const tmpToken = params.token;
      localStorage.setItem("token", tmpToken);
      await put("/auth/new_password", { password });

      await openAlert(
        "Contraseña reestablecida",
        <>
          Se ha cambiado la contraseña correctamente.
        </>,
        undefined, true, "info", undefined, 6000,
      );
      navigate('/login');

    } catch (error) {
      const message = _.get(error, "response.data.errors[0].msg");
      openAlert(
        "Error al enviar correo",
        <>
          Ocurrió un error al cambiar contraseña.<br />
          {message
            ? <b>{message}</b>
            : "Inténtelo de nuevo más tarde"}.
        </>,
        undefined, true, "secondary", undefined, undefined,
      );
    } finally {
      localStorage.removeItem("token");
      setLoading(false);
    }
  };

  return (
    <PageWithCenteredSquare>
      <form onSubmit={handleSubmit} className="needs-validation" noValidate>

        <h5 className="mt-2 text-uni">Reestablecer contraseña</h5>

        <div className="py-2">

          <PageParagraph>
            Ingrese los nuevos datos.
          </PageParagraph>

          <FormTextInput
            label="Nueva contraseña"
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={onInputChange}
            placeholder="************"
            invalidFeedback={errors.password}
            required
          />

          <FormTextInput
            label="Repita la contraseña"
            id="repeatPassword"
            type="password"
            name="repeatPassword"
            value={repeatPassword}
            onChange={onInputChange}
            placeholder="************"
            invalidFeedback={errors.repeatPassword}
            required
          />
        </div>

        <div className="pt-2">
          <LargeButton value="Cambiar contraseña" classType="primary" type="submit" />
        </div>
      </form>
    </PageWithCenteredSquare>
  );
};
